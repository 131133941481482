<template>
  <div class="Popover">
    <header class="Popover__Header">
			<span class="Popover__Header__Label">
				Pallet:
				<span>{{ palletNumber }}</span>
			</span>
    </header>
    <main>
      <b-row>
        <span class="Popover__Label">Producto:</span>
      </b-row>
      <b-row class="mb-2">
        <span class="Popover__Data">{{ product }}</span>
      </b-row>
      <b-row>
        <span class="Popover__Label">Peso:</span>
      </b-row>
      <b-row class="mb-2">
        <span class="Popover__Data">{{ weight }} Kg.</span>
      </b-row>
      <b-row v-if="pallet.TieneDetalle === false">
        <span class="Popover__Label">Fecha producción:</span>
      </b-row>
      <b-row class="mb-2" v-if="pallet.TieneDetalle === false">
        <span class="Popover__Data">{{ productionDate }}</span>
      </b-row>
      <b-row v-if="pallet.TieneDetalle === false">
        <span class="Popover__Label">Fecha ingreso:</span>
      </b-row>
      <b-row class="mb-2" v-if="pallet.TieneDetalle === false">
        <span class="Popover__Data">{{ receptionDate }}</span>
      </b-row>
      <b-row class="mb-2" align-h="center" v-if="pallet.TieneDetalle === true">
        <span class="Popover__Label">Pallet compuesto</span>
      </b-row>

      <div class="Popover__Actions mt-3">
        <b-row>
          <b-button variant="outline-primary" size="lg" @click="onRemove">Dar salida</b-button>
        </b-row>
        <b-row>
          <b-button variant="outline-secondary" class="mt-2" size="lg" @click="onChangeWarehouse">
            Mover de cámara
          </b-button>
        </b-row>
      </div>
    </main>
    <b-modal centered id="CameraSelector" aria-label="Selección de cámara">
      <template v-slot:modal-header="{ close }">
        <CloseBar title="Cámaras" @close="close()"/>
      </template>
      <b-container fluid>
        <b-row>
          <b-col cols="12">
            <CameraSelector :default-behaviour="false" @selected-camera="changePalletCamera" @close="hideCameraSelector"/>
          </b-col>
        </b-row>
      </b-container>

      <template v-slot:modal-footer>
        <div></div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import {getPallet as getApiPallet, getPalletDetails,movePallet} from '@/api'
import {mapGetters} from 'vuex'
import CameraSelector from "@/components/Pages/CameraSelector";
import CloseBar from "@/components/CloseBar";


export default {
  props: {
    palletCode: Number,
    palletNumber: Number
  },
  components: {
    CameraSelector,
    CloseBar
  },
  data() {
    return {
      palletDetails: null,
      product: '',
      weight: 0,
      productionDate: '',
      receptionDate: '',
      pallet: {}
    }
  },
  computed: {
    ...mapGetters([
      'getActiveProducts'
    ])
  },
  methods: {
    async getPallet() {
      this.pallet = await getApiPallet(this.palletCode)
      this.palletDetails = await getPalletDetails(this.palletCode)
      if (this.palletDetails.length > 0) {
        const productCode = this.palletDetails[0].Talle.ProductoCodigo
        this.getActiveProducts.forEach((element) => {
          if (element.Codigo === productCode) {
            this.product = element.Nombre
          }
        })
      }
      this.weight = 0
      for (let i = 0; i < this.palletDetails.length; i++) {
        this.weight = this.weight + parseInt(this.palletDetails[i].Peso)
      }
      if (this.palletDetails.length === 1) {
        this.productionDate = this.$moment.tz(this.palletDetails[0].FechaProduccion, "America/Montevideo").format('DD/MM/YYYY')
        this.receptionDate = this.$moment.tz(this.palletDetails[0].FechaIngreso, "America/Montevideo").format('DD/MM/YYYY HH:mm')
      }
    },
    async changePalletCamera(codigo) {
      await movePallet(this.palletCode,0,codigo)
      await this.$store.dispatch('setClosePopover', `pallet-${this.palletCode}-popover`)
      await this.$store.dispatch('setPalletsInTemporaryLocation', codigo)
      await this.$store.dispatch('setCurrentCamera', codigo)
    },
    onRemove() {
      this.$store.dispatch('setCurrentOperation', 'remove')
      this.pallet.details = this.palletDetails
      this.$store.dispatch('setCurrentPallet', this.pallet)
      this.$store.dispatch('setClosePopover', `pallet-${this.palletCode}-popover`)
      this.$store.dispatch('openPallet')
      window.scrollTo(0, 0)
    },
    onChangeWarehouse() {
      this.$bvModal.show('CameraSelector')
    },
    hideCameraSelector() {
      this.$bvModal.hide('CameraSelector')
    },
  },
  created() {
    this.getPallet()
  }

}
</script>

<style lang="scss" scoped>
.Popover {
  &__Header {
    background-color: transparent;
    width: 272px;
    margin: -.45rem -.7rem;
    padding: 0 1rem;
    height: 33px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.2rem;
    border-bottom-style: solid;
    border-bottom-color: #AAA;
    border-bottom-width: 1px;

    &__Label {
      font-weight: 700;

      & span {
        font-weight: 400;
      }
    }
  }

  &__Data {
    font-size: 1.1rem;
    display: block;
    padding-left: 10px;
  }

  &__Label {
    font-size: 1.1rem;
    margin-right: 10px;
    font-weight: 700;
  }

  &__Actions {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
  }
}

main {
  padding: 20px 10px;
}

#CameraSelector___BV_modal_outer_ {
  z-index: 1060 !important;
}
</style>
