export const mutations = {
  SET_LOGGED_IN(state, idToken) {
    state.user.loggedIn = true;
    state.user.idToken = idToken;
  },
  SET_LOGGED_OUT(state) {
    state.user.loggedIn = false;
    state.user.idToken = "";
  },
  SET_USER(state, data) {
    state.user.profileData = data;
  },
  REFRESH_TOKEN(state, idToken) {
    state.user.idToken = idToken.idToken;
  },
  SET_PRODUCT_LIST(state, productList) {
    state.products = productList;
  },
  SET_CAMERA_LIST(state, cameraList) {
    state.cameras = cameraList;
  },
  SET_CURRENT_CAMERA(state, camera) {
    state.currentCamera = camera;
  },
  SET_PALLET_LOCATION(state, location) {
    state.palletLocation = location;
  },
  RESET_STATE(state) {
    state.user = {
      user: {
        loggedIn: false,
        profileData: null,
      },
    };
    state.products = [];
    state.cameras = [];
    state.currentCamera = null;
    state.palletLocation = {
      sectorPosition: 0,
      column: 0,
      floor: 0,
      position: 0,
    };
    state.currentPopover = null;
    state.popoverToClose = null;
    state.currentPalletDetails = [];
    state.palletsInTemporaryLocation = [];
    state.current = {
      operation: "",
      pallet: null,
    };
    state.filterSector = null;
    state.filterPalletNumber = null;
    state.filterProduct = null;
    state.filterSize = null;
    state.filterPresentation = null;
  },
  SET_CURRENT_POPOVER(state, popoverLocation) {
    state.currentPopover = popoverLocation;
  },
  SET_CLOSE_POPOVER(state, popoverLocation) {
    state.popoverToClose = popoverLocation;
  },
  SET_CURRENT_PALLET_DETAILS(state, value) {
    state.currentPalletDetails = value;
  },
  SET_CURRENT_OPERATION(state, value) {
    state.current.operation = value;
  },
  SET_CURRENT_PALLET(state, value) {
    state.current.pallet = value;
  },
  OPEN_PALLET(state, value) {
    state.eventOpenPallet = value;
  },
  SET_PALLETS_IN_TEMPORARY_LOCATION(state, value) {
    state.palletsInTemporaryLocation = value;
  },
  REMOVE_PALLET_FROM_TEMPORARY_LOCATION(state, palletCode) {
    for (var i = 0; i < state.palletsInTemporaryLocation.length; i++) {
      if (state.palletsInTemporaryLocation[i].Codigo === palletCode) {
        state.palletsInTemporaryLocation.splice(i, 1);
      }
    }
  },
  CLEAR_FILTERS(state) {
    state.filterSector = null;
    state.filterPalletNumber = null;
    state.filterProduct = null;
    state.filterSize = null;
    state.filterPresentation = null;
  },
  SET_FILTER_SECTOR(state, value) {
    state.filterSector = value;
  },
  SET_FILTER_PALLET_NUMBER(state, value) {
    state.filterPalletNumber = value;
  },
  SET_FILTER_PRODUCT(state, value) {
    state.filterProduct = value;
  },
  SET_FILTER_SIZE(state, value) {
    state.filterSize = value;
  },
  SET_FILTER_PRESENTATION(state, value) {
    state.filterPresentation = value;
  },
  SOCKET_ONOPEN(state) {
    state.socket.isConnected = true;
  },
  SOCKET_ONCLOSE(state) {
    // console.log('SOCKET_ONCLOSE');
    state.socket.isConnected = false;
  },
  SOCKET_ONERROR(state, event) {
    //eslint-disable-next-line
    console.error(state, event);
  },
  // mutations for reconnect methods
  SOCKET_RECONNECT() {},
  SOCKET_RECONNECT_ERROR(state) {
    state.socket.reconnectError = true;
  },
  SOCKET_ONMESSAGE(state, message) {
    const parsedMessage = JSON.parse(message.data);
    state.socket.message = parsedMessage;
    switch (parsedMessage.topic) {
      case "cameraUpdated":
        if (state.currentCamera.Codigo === parsedMessage.code) {
          this.dispatch("setCurrentCameraWithContent", parsedMessage.payload);
        }
        break;
      case "temporaryPallets":
        if (state.currentCamera.Codigo === parsedMessage.code) {
          this.dispatch(
            "setPalletsInTemporaryLocationWithContent",
            parsedMessage.payload
          );
        }
        break;
      default:
        //eslint-disable-next-line
        console.error("Unknown webscoket message received");
    }
  },
};
