export const getters = {
  user(state) {
    return state.user;
  },
  userEmail(state) {
    if (state.user.profileData && state.user.profileData.email)
      return state.user.profileData.email;
    else return "";
  },
  userDisplayName(state) {
    if (state.user.profileData && state.user.profileData.displayName)
      return state.user.profileData.displayName;
    else return "";
  },
  getCameras(state) {
    // TODO: Add sort in server side
    const list = state.cameras.filter((cam) => cam.Estado === "ACTIVO");
    return list.sort((a, b) => {
      if (a.Numero <= b.Numero) return -1;
      return 1;
    });
  },
  getCurrentCamera(state) {
    return state.currentCamera;
  },
  getUbicaciones(state) {
    return (Sector, Columna) => {
      if (state.currentCamera && state.currentCamera.Sectores[Sector]) {
        return state.currentCamera.Sectores[Sector].Ubicaciones.filter(
          (elem) => {
            if (elem.Columna && elem.Columna === Columna) return true;
          }
        );
      }
    };
  },
  getActiveProducts(state) {
    return state.products.filter((prod) => prod.Estado === "ACTIVO");
  },
  getSizesOfProduct(state) {
    return (productCode) => {
      return state.products.find((prod) => prod.Codigo === productCode).Talles;
    };
  },
  getPresentationsOfProduct(state) {
    return (productCode) => {
      return state.products.find((prod) => prod.Codigo === productCode)
        .Presentaciones;
    };
  },
  getPresentationPalletWeight(state) {
    return (productCode, presentationCode) => {
      const product = state.products.find(
        (prod) => prod.Codigo === productCode
      );
      return product.Presentaciones.find(
        (pres) => pres.Codigo === presentationCode
      );
    };
  },
  getPalletLocation(state) {
    return state.palletLocation;
  },
  getCurrentPalletDetails(state) {
    return state.currentPalletDetails;
  },
  getCurrent(state) {
    return state.current;
  },
  getOpenPallet(state) {
    return state.eventOpenPallet;
  },
  getPalletsInTemporaryLocation(state) {
    return state.palletsInTemporaryLocation;
  },
  getFilterSector(state) {
    return state.filterSector;
  },
  getFilterPalletNumber(state) {
    return state.filterPalletNumber;
  },
  getFilterProduct(state) {
    return state.filterProduct;
  },
  getFilterSize(state) {
    return state.filterSize;
  },
  getFilterPresentation(state) {
    return state.filterPresentation;
  },
  getCurrentPopover(state) {
    return state.currentPopover;
  },
  getPopoverToClose(state) {
    return state.popoverToClose;
  },
};
