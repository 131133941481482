import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import * as SecureLS from 'secure-ls'
import { state } from './state.js'
import { mutations } from './mutations.js'
import { getters } from './getters.js'
import { actions } from './actions.js'


const ls = new SecureLS({ isCompression: false })

Vue.use(Vuex)

export const store = new Vuex.Store({
	state,
	getters,
	mutations,
	actions,
	plugins: [createPersistedState({
		storage: {
			getItem: key => ls.get(key),
			setItem: (key, value) => ls.set(key, value),
			removeItem: key => ls.remove(key)
		}
	})],
	strict: process.env.VUE_APP_STRICT_STORE
})