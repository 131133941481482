<template>
	<div class="PalletWW">
		<h1>Trabajar con Pallets</h1>
		<div class="PalletWW__Filters PalletWW__Body">
			<b-form class="PalletWW__FiltersForm">
				<b-form-row>
					<b-col sm="3">
						<b-form-group
							id="palletNumber-InputGroup"
							label="Número Pallet"
							label-for="palletNumber-Input"
							label-size="lg"
						>
							<b-form-input
								id="palletNumber-Input"
								type="text"
								size="lg"
								v-model="palletNumber"
								@keypress="validateKeypress"
							/>
						</b-form-group>
					</b-col>
					<b-col sm="3" offset-sm="1">
						<b-form-group
							id="product-InputGroup"
							label="Producto"
							label-for="product-Input"
							label-size="lg"
						>
							<b-form-select id="product-Input" v-model="product" @change="productSelected" size="lg" :options="productOptions"></b-form-select>
						</b-form-group>
					</b-col>
					<b-col sm="3" offset-sm="1">
						<b-form-group
							id="destination-InputGroup"
							label="Destino"
							label-for="destination-Input"
							label-size="lg"
						>
							<b-form-select id="destination-Input" v-model="destination" size="lg" :options="destinationOptions"></b-form-select>
						</b-form-group>
					</b-col>
				</b-form-row>
				<b-form-row>
					<b-col sm="3">
						<b-form-group
							id="active-InputGroup"
							label="Activo"
							label-for="active-Input"
							label-size="lg"
						>
							<b-form-select id="active-Input" v-model="active" size="lg" :options="activeOptions"></b-form-select>
						</b-form-group>
					</b-col>
					<b-col sm="3" offset-sm="1">
						<b-form-group
							id="size-InputGroup"
							label="Talle"
							label-for="size-Input"
							label-size="lg"
						>
							<b-form-select id="size-Input" v-model="size" size="lg" :disabled="this.product == null" :options="sizeOptions"></b-form-select>
						</b-form-group>
					</b-col>
					<b-col sm="3" offset-sm="1">
						<b-form-group
							id="presentation-InputGroup"
							label="Presentación"
							label-for="presentation-Input"
							label-size="lg"
						>
							<b-form-select id="presentation-Input" v-model="presentation" size="lg" :disabled="this.product == null" :options="presentationOptions"></b-form-select>
						</b-form-group>
					</b-col>
				</b-form-row>
				<b-form-row>
					<b-col sm="5">
						<div class="PalletWW__FiltersGroup">
							<label class="PalletWW__FiltersGroupLabel">
								Fecha de Ingreso
							</label>
							<img 
								src="broom-filter.png" 
								alt="Limpiar filtros de fecha de ingreso" 
								title="Limpiar filtros" 
								class="PalletWW__FiltersGroupClear"
								@click="clearReceptionDate"
							>
							<b-row>
								<b-col sm="5">
									<b-form-group
										id="FechaIngresoDesde-InputGroup"
										label="Desde"
										label-for="FechaIngresoDesde-Input"
										label-size="lg"
									>
										<datetime
											input-id="FechaIngresoDesde-Input"
											v-model="receptionDateFrom"
											type="datetime"
											:phrases="{ok: 'Aceptar', cancel: 'Cancelar'}"
											value-zone="America/Montevideo"
											zone="America/Montevideo"
											input-class="PalletWW__FiltersDate"
										/>
									</b-form-group>
								</b-col>
								<b-col sm="5" offset-sm="1">
									<b-form-group
										id="FechaIngresoHasta-InputGroup"
										label="Hasta"
										label-for="FechaIngresoHasta-Input"
										label-size="lg"
									>
										<datetime
											input-id="FechaIngresoHasta-Input"
											v-model="receptionDateTo"
											type="datetime"
											:phrases="{ok: 'Aceptar', cancel: 'Cancelar'}"
											value-zone="America/Montevideo"
											zone="America/Montevideo"
											input-class="PalletWW__FiltersDate"
										/>
									</b-form-group>
								</b-col>
							</b-row>
						</div>
					</b-col>
					<b-col sm="5" offset-sm="1">
						<div class="PalletWW__FiltersGroup">
							<label class="PalletWW__FiltersGroupLabel">
								Fecha de Producción
							</label>
							<img 
								src="broom-filter.png" 
								alt="Limpiar filtros de fecha de produccion" 
								title="Limpiar filtros" 
								class="PalletWW__FiltersGroupClear"
							>
							<b-row>
								<b-col sm="5">
									<b-form-group
										id="FechaProduccionDesde-InputGroup"
										label="Desde"
										label-for="FechaProduccionDesde-Input"
										label-size="lg"
									>
										<datetime
											input-id="FechaProduccionDesde-Input"
											v-model="productionDateFrom"
											type="date"
											:phrases="{ok: 'Aceptar', cancel: 'Cancelar'}"
											value-zone="America/Montevideo"
											zone="America/Montevideo"
											input-class="PalletWW__FiltersDate"
										/>
									</b-form-group>
								</b-col>
								<b-col sm="5" offset-sm="1">
									<b-form-group
										id="FechaProduccionHasta-InputGroup"
										label="Hasta"
										label-for="FechaProduccionHasta-Input"
										label-size="lg"
									>
										<datetime
											input-id="FechaProduccionHasta-Input"
											v-model="productionDateTo"
											type="date"
											:phrases="{ok: 'Aceptar', cancel: 'Cancelar'}"
											value-zone="America/Montevideo"
											zone="America/Montevideo"
											input-class="PalletWW__FiltersDate"
										/>
									</b-form-group>
								</b-col>
							</b-row>
						</div>
					</b-col>
					<b-col>
						<div class="Search__Container">
							<b-button size="lg" variant="primary" @click="filterApplied" > Buscar </b-button>
						</div>
					</b-col>
				</b-form-row>
			</b-form>
		</div>
		<div class="PalletWW__Table PalletWW__Body">
			<!-- TODO: add some loader to export button -->
			<img 
				src="table-export.png" 
				alt="Exportar la información del pallet" 
				title="Exportar" 
				class="PalletWW__TableExport"
				@click="exportPalletInformation"
			>

			<!-- TODO: change pallet.get to get all information at once, with infered attributes (like product) -->

			<b-table responsive="sm" primary-key="Codigo" head-variant="dark" outlined striped :fields="palletsFields" :items="palletsItems" :busy="loading">
				<template v-slot:table-busy>
					<div class="text-center text-danger my-2">
						<b-spinner class="align-middle"></b-spinner>
						<strong>Cargando...</strong>
					</div>
				</template>
				<template v-slot:cell(show_details)="row">
					<b-button v-if="row.item.TieneDetalle" size="sm" variant="primary" @click="row.toggleDetails" class="mr-2">
						{{ row.detailsShowing ? 'Ocultar' : 'Mostrar'}} detalles
					</b-button>
				</template>
				<template v-slot:row-details="row">
					<b-card>
						<b-table responsive="sm" primary-key="Codigo" bordered table-variant="warning" head-variant="dark"  :fields="palletDetailsFields" :items="getDetailItems(row.item.Codigo)">
						</b-table>	
						<b-button variant="primary" size="sm" @click="row.toggleDetails">Ocultar detalles</b-button>
					</b-card>
				</template>
			</b-table>
			<b-pagination
				v-model="currentPage"
				:per-page="perPage"
				:total-rows="totalRows"
				limit="5"
				align="right"
				aria-controls="products-table"
			/>
		</div>
	</div>
</template>

<script>
import { getPalletsByPage, getPalletsExport, getProducts, getDestinations, getSizesByProduct, getPresentationsByProduct } from '@/api'
import { Datetime } from 'vue-datetime'

export default {
	data() {
		const endDay = this.$moment(new Date()).hour(23).minute(59).second(59)
		return {
			loading: false,
			pallets: null,
			detailsHash: new Map(),
			sizesMap: new Map(),
			palletsFields: [
				{ key: 'Codigo', sortable: true, label: 'Código', class: 'hidden' },
				{ key: 'Numero', sortable: true, label: 'Número' },
				{ key: 'Producto', sortable: true, label: 'Producto'},
				{ key: 'Peso', sortable: false, label: 'Peso' },
				{ key: 'Estado', sortable: true, label: 'Estado' },
				{ key: 'Completo', sortable: false, label: "Completo" },
				{ key: 'Origen', sortable: false, label: 'Origen' },
				{ key: 'Destino', sortable: true, label: 'Destino' },
				{ key: 'FechaIngreso', sortable: true, label: 'Fecha de Ingreso' },
				{ key: 'FechaProduccion', sortable: true, label: 'Fecha de Producción' },
				{ key: 'CodigoProduccion', sortable: false, label: 'Código Producción' },
				{ key: 'FechaVencimiento', sortable: true, label: 'Fecha de Vencimiento' },
				{ key: 'FechaSalida', sortable: true, label: 'Fecha de Salida' },
				{ key: 'show_details', label: ''},
				{ key: 'TieneDetalle', sortable: false, label: '', class: 'hidden' }
			],
			palletsItems: null,
			palletDetailsFields: [ 
				{ key: 'Codigo', sortable: false, label: "Código", class: 'hidden' },
				{ key: 'Producto', sortable: true, label: 'Producto'},
				{ key: 'Peso', sortable: false, label: "Peso" },
				{ key: 'Cajas', sortable: false, label: "Cajas" },
				{ key: 'Origen', sortable: false, label: "Origen" },
				{ key: 'FechaIngreso', sortable: true, label: "Fecha de Ingreso"},
				{ key: 'FechaProduccion', sortable: true, label: "Fecha de Produccion"},
				{ key: 'CodigoProduccion', sortable: false, label: "Código Producción"},
				{ key: 'FechaVencimiento', sortable: true, label: "Fecha de Vencimiento"}
			],
			product: null,
			destination: null,
			active: null,
			size: null,
			presentation: null,
			productOptions: [
				{ value: null, text: '[Todos]' }
			],
			destinationOptions: [
				{ value: null, text: '[Todos]' }
			],
			activeOptions: [
				{ value: null, text: '[Todos]' },
				{ value: 'ACTIVO', text: 'ACTIVO' },
				{ value: 'INACTIVO', text: 'INACTIVO' }
			],
			sizeOptions: [
				{ value: null, text: '[Todos]' }
			],
			presentationOptions: [
				{ value: null, text: '[Todos]' }
			],
			palletNumber: null,
			receptionDateFrom: this.$moment.tz(this.$moment(new Date())
				.add(-1, 'month').hour(0).minute(0).second(0)
				, "America/Montevideo").format('YYYY-MM-DDTHH:mm:ss'),
			receptionDateTo: this.$moment.tz(endDay, "America/Montevideo").format('YYYY-MM-DDTHH:mm:ss'),
			productionDateFrom: null,
			productionDateTo: null,
			currentPage: 1,
			perPage: 10,
			totalRows: 0
		}
	},
	beforeMount () {
		this.currentPage = 1
	},
	components: { 
		Datetime
	},
	methods: {
		clearReceptionDate() {
			const endDay = this.$moment(new Date()).hour(23).minute(59).second(59)
			this.receptionDateFrom = this.$moment.tz(this.$moment(new Date())
				.add(-1, 'month').hour(0).minute(0).second(0)
				, "America/Montevideo").format('YYYY-MM-DDTHH:mm:ss')
			this.receptionDateTo = this.$moment.tz(endDay, "America/Montevideo").format('YYYY-MM-DDTHH:mm:ss')
		},
		clearProductionDate() {
			this.productionDateFrom = null
			this.productionDateTo = null
		},
		async exportPalletInformation() {
			try {
				await getPalletsExport(
					this.palletNumber, 
					this.product,
					this.receptionDateFrom ? this.$moment.tz(this.receptionDateFrom, "America/Montevideo").format('YYYYMMDDTHHmmss') : null,
					this.receptionDateTo ? this.$moment.tz(this.receptionDateTo, "America/Montevideo").format('YYYYMMDDTHHmmss') : null,
					this.productionDateFrom ? this.$moment.tz(this.productionDateFrom, "America/Montevideo").format('YYYYMMDD') : null,
					this.productionDateTo ? this.$moment.tz(this.productionDateTo, "America/Montevideo").format('YYYYMMDD') : null,
					this.destination,
					this.size,
					this.presentation,
					this.active
				)
			} catch (e) {
				this.toast(`Ocurrió un error al realizar la acción: ${e}`)
			}
		},
		async fetchData() {
			if (this.productOptions.length === 1 /* null value only */) {
				this.loading = true
				try {
					const responses = await Promise.all([
						getProducts(),
						getDestinations(),
						getPalletsByPage(
							null,
							null, 
							this.receptionDateFrom ? this.$moment.tz(this.receptionDateFrom, "America/Montevideo").format('YYYYMMDDTHHmmss') : null,
							this.receptionDateTo ? this.$moment.tz(this.receptionDateTo, "America/Montevideo").format('YYYYMMDDTHHmmss') : null,
							null,
							null,
							null,
							null,
							null,
							null,
							this.currentPage,
							this.perPage
						)
					])

					// Products
					const colProducts = responses[0]
					for (let i = 0; i < colProducts.length; i++) {
						const element = colProducts[i]
						this.productOptions.push({value: element.Codigo, text: element.Nombre})
					}

					// Destinations
					const colDestinations = responses[1]
					for (let i = 0; i < colDestinations.length; i++) {
						const element = colDestinations[i]
						this.destinationOptions.push({value: element.Codigo, text: element.Nombre})
					}

					// Pallets
					this.pallets = responses[2].pallets
					this.totalRows = responses[2].palletCount
					this.assemblePalletItems()
				} catch (e) {
					// eslint-disable-next-line
					console.log(e)
				}
				this.loading = false
			} else {
				this.loading = true
				try {
					var response = await getPalletsByPage(
						this.palletNumber, 
						this.product,
						this.receptionDateFrom ? this.$moment.tz(this.receptionDateFrom, "America/Montevideo").format('YYYYMMDDTHHmmss') : null,
						this.receptionDateTo ? this.$moment.tz(this.receptionDateTo, "America/Montevideo").format('YYYYMMDDTHHmmss') : null,
						this.productionDateFrom ? this.$moment.tz(this.productionDateFrom, "America/Montevideo").format('YYYYMMDD') : null,
						this.productionDateTo ? this.$moment.tz(this.productionDateTo, "America/Montevideo").format('YYYYMMDD') : null,
						this.destination,
						this.size,
						this.presentation,
						this.active,
						this.currentPage,
						this.perPage
					)
					this.pallets = response.pallets
					this.totalRows = response.palletCount
					this.assemblePalletItems()
				} catch (e) {
					// eslint-disable-next-line
					console.log(e)
				}
				this.loading = false
			}			
		},
		async fetchDataByProduct() {
			const responses = await Promise.all([
				getSizesByProduct(this.product),
				getPresentationsByProduct(this.product)
			])
				
			// Sizes
			const colSizes = responses[0]
			for (let i = 0; i < colSizes.length; i++) {
				const element = colSizes[i]
				this.sizeOptions.push({value: element.Codigo, text: element.Nombre})
			}

			// Presentations
			const colPresentations = responses[1]
			for (let i = 0; i < colPresentations.length; i++) {
				const element = colPresentations[i]
				this.presentationOptions.push({value: element.Codigo, text: element.Descripcion})
			}
		},
		async assemblePalletItems() {
			this.palletsItems = []
			if (!this.pallets) {
				return
			}

			for (let i = 0; i < this.pallets.length; i++ ) {
				const pallet = this.pallets[i]
				
				let product = pallet.Detalles[0].Talle.Producto.Nombre
				let productCode = pallet.Detalles[0].Talle.Producto.Codigo
				let weight = 0
				let origin = pallet.Detalles[0].Origen
				let receptionDate = pallet.Detalles[0].FechaIngreso
				let productionDate = pallet.Detalles[0].FechaProduccion
				let productionCode = this.getProductionNumber(pallet.Detalles[0].FechaProduccion)
				let expiryDate = pallet.Detalles[0].FechaVencimiento
				let details = []

				pallet.Detalles.forEach(element => {
					weight += element.Peso
					if (productCode !== element.Talle.Producto.Codigo) {
						product = 'Ver detalle'
					}
					if (origin !== element.Origen) {
						origin = 'Ver detalle'
					}
					if (receptionDate !== element.FechaIngreso) {
						receptionDate = 'Ver detalle'
					}
					if (productionDate !== element.FechaProduccion) {
						productionDate = 'Ver detalle'
					}
					if (productionCode !== this.getProductionNumber(element.FechaProduccion)) {
						productionCode = 'Ver detalle'
					}
					if (expiryDate !== element.FechaVencimiento) {
						expiryDate = 'Ver detalle'
					}
					

					details.push({
						Producto: element.Talle.Producto.Nombre,
						Peso: `${element.Peso} Kg.`,
						Cajas: element.Cajas,
						Origen: this.capitalizeFirstLetter(element.Origen.toLowerCase()),
						FechaIngreso: this.$moment.tz(element.FechaIngreso, "America/Montevideo").format('DD/MM/YYYY HH:mm'),
						FechaProduccion: this.$moment.tz(element.FechaProduccion, "America/Montevideo").format('DD/MM/YYYY'),
						CodigoProduccion: this.getProductionNumber(element.FechaProduccion),
						FechaVencimiento: element.FechaVencimiento ? this.$moment.tz(element.FechaVencimiento, "America/Montevideo").format('DD/MM/YYYY') : 'N/A',
						Codigo: element.Codigo,
					})
					this.detailsHash.set(pallet.Codigo, details)
				})

				this.palletsItems.push({ 
					Codigo: pallet.Codigo,
					Numero: pallet.Numero,
					Producto: product,
					Peso: `${weight} Kg.`,
					Estado: this.capitalizeFirstLetter(pallet.Estado.toLowerCase()),
					Completo: pallet.EsPesoCompleto ? 'Si' : 'No',
					Origen: this.capitalizeFirstLetter(origin.toLowerCase()),
					Destino: pallet.Destino == null ? 'N/A' : pallet.Destino.Nombre,
					FechaIngreso: receptionDate === 'Ver detalle' ? 'Ver detalle' : this.$moment.tz(receptionDate, "America/Montevideo").format('DD/MM/YYYY HH:mm'),
					FechaProduccion: productionDate === 'Ver detalle' ? 'Ver detalle' : this.$moment.tz(productionDate, "America/Montevideo").format('DD/MM/YYYY'),
					CodigoProduccion: this.getProductionNumber(productionDate),
					FechaVencimiento: expiryDate === 'Ver detalle' ? 'Ver detalle' : expiryDate == null ? 'N/A' : this.$moment.tz(expiryDate, "America/Montevideo").format('DD/MM/YYYY'),
					FechaSalida: pallet.FechaSalida == null ? 'N/A' : this.$moment.tz(pallet.FechaSalida, "America/Montevideo").format('DD/MM/YYYY HH:mm'),
					TieneDetalle: pallet.TieneDetalle
				})
			}
		},
		capitalizeFirstLetter(string) {
			return string.charAt(0).toUpperCase() + string.slice(1);
		},
		getDetailItems(palletId) {
			return this.detailsHash.get(palletId)
		},
		productSelected() {
			this.sizeOptions = [ { value: null, text: '[Todos]' } ]
			this.size = null
			
			this.presentationOptions = [ { value: null, text: '[Todos]' } ]
			this.presentation = null
			
			if (this.product != null) {
				this.fetchDataByProduct().catch((error) => {
					this.toast(`Ocurrió un error al realizar la acción: ${error}`)
				})
			}
		},
		filterApplied() {
			this.fetchData().catch((error) => {
				this.toast(`Ocurrió un error al realizar la accion: ${error}`)
			})
		},
		getProductionNumber (productionDate) {
			if (productionDate && productionDate.length > 0) {
				if (productionDate === 'Ver detalle') {
					return productionDate
				}
				let year = this.$moment.tz(productionDate, "America/Montevideo").year()
				let dayOfYear = this.$moment.tz(productionDate, "America/Montevideo").dayOfYear()
				return `${year}-${dayOfYear}`
			} else {
				return ''
			}
		},
		getDestination(destinationCode) {
			let destinationString = 'N/A'
			if (destinationCode) {
				for (let i = 0; i < this.destinationOptions.length; i++) {
					let elem = this.destinationOptions[i]
					if (elem.value === destinationCode) {
						destinationString = elem.text
						break
					}
				}
			}
			return destinationString
		},
		validateKeypress($event) {
			if ($event.code === 'Enter'){
				this.filterApplied()
			}
		},
		watchHandler() {
			this.fetchData().catch((error) => {
				this.toast(`Ocurrió un error al realizar la accion: ${error}`)
			})
		},
		toast(message) {
			this.$bvToast.toast(message, {
				title: 'Error',
				toaster: 'b-toaster-top-full',
				solid: true,
				variant: 'danger',
				appendToast: false
			})
		}
	},
	watch: {
		currentPage: {
			handler () {
				this.watchHandler()
			}
		}
	},
	created() {
		this.fetchData().catch((error) => {
			this.toast(`Ocurrió un error al realizar la accion: ${error}`)
		})
	}
}
</script>

<style lang="scss" scoped>
	.PalletWW {
		padding: 2rem;
		background-color: #ECECEC;

		&__Body {
			margin-top: 2rem;
			padding: 2.5rem;
			background-color: white;
			border-top-color: #9BD;
			border-top-style: solid;
			border-top-width: 5px;
		}

		&__Table {
			padding-top: 1rem;

			&Export {
				float: right;
				cursor: pointer;
				padding-bottom: 1rem;
				padding-right: 35px;
			}
		}

		&__Filters {
			&Group {
				padding: 1rem;
				border-style: solid;
				border-width: 2px;
				border-radius: 4px;
				border-color: #9BD;
				position: relative;
				margin-top: 2rem;

				&Clear {
					float: right;
					cursor: pointer;
				}

				&Label {
					position: absolute;
					background-color: #FFF;
					padding: 0 10px;
					top: -1rem;
					font-size: 1.2rem;
				}
			}
		}	
	}

	.Search__Container {
		width: 100%;
		height: 100%;
		display: flex;
		align-items: flex-end;
		justify-content: center;
	}
</style>

<style lang="scss">
	.PalletWW__FiltersDate {
		border-color: #DADFE3;
		border-style: solid;
		border-width: 1px;
		border-radius: 3px;
		width: 100%;
		height: calc(1.5em + 1rem + 2px) !important;
		padding-left: 10px;
		font-size: 1.2rem;
	}

	.table th, .table td {
		text-align: center;
	}

	.hidden {
		display: none;
	}

	.app__Content {
		background-color: #ECECEC !important;
	}
</style>