import axios from "axios";
import { store } from "@/store";
import { palletsInTemporaryLocation } from "@/api";
import { validAuth } from "@/firebase/utils.js";

export const actions = {
  setUserLogged({ commit }, data) {
    commit("SET_LOGGED_IN", data.idToken);
    if (data.user)
      commit("SET_USER", {
        email: data.user.email,
        displayName: data.user.displayName,
      });
  },
  setUserLoggedOut({ commit }) {
    commit("SET_LOGGED_OUT");
    commit("SET_USER", null);
  },
  setRefreshToken({ commit }, idToken) {
    commit("REFRESH_TOKEN", idToken);
  },
  async fetchCameraList({ commit }) {
    await validAuth();
    const token = store.getters.user.idToken;
    const baseUrl = process.env.VUE_APP_SERVER_ENDPOINT;
    const request = await axios.request({
      url: `${baseUrl}/api/camera`,
      method: "get",
      responseType: "json",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    commit("SET_CAMERA_LIST", request.data);
  },
  async setCurrentCameraWithContent({ commit }, payload) {
    commit("SET_CURRENT_CAMERA", payload);
  },
  async setCurrentCamera({ commit }, camaraCodigo) {
    await validAuth();
    if (camaraCodigo) {
      const token = store.getters.user.idToken;
      const baseUrl = process.env.VUE_APP_SERVER_ENDPOINT;
      const request = await axios.request({
        url: `${baseUrl}/api/camera/${camaraCodigo}`,
        method: "get",
        responseType: "json",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      for (let i = 0; i < request.data[0].Sectores.length; i++) {
        let sector = request.data[0].Sectores[i];
        let sectorRequest = await axios.request({
          url: `${baseUrl}/api/location/bySector/${sector.Codigo}`,
          method: "get",
          responseType: "json",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        sector.Ubicaciones = sectorRequest.data;
      }
      commit("SET_CURRENT_CAMERA", request.data[0]);
    } else {
      commit("SET_CURRENT_CAMERA", null);
    }
  },
  async fetchProductList({ commit }) {
    await validAuth();
    const token = store.getters.user.idToken;
    const baseUrl = process.env.VUE_APP_SERVER_ENDPOINT;
    const request = await axios.request({
      url: `${baseUrl}/api/product`,
      method: "get",
      responseType: "json",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    commit("SET_PRODUCT_LIST", request.data);
  },
  setPalletLocation({ commit }, palletLocation) {
    commit("SET_PALLET_LOCATION", palletLocation);
  },
  resetStore({ commit }) {
    commit("RESET_STATE");
  },
  togglePopover({ commit }, popoverLocation) {
    commit("SET_CURRENT_POPOVER", popoverLocation);
  },
  setClosePopover({ commit }, popoverLocation) {
    commit("SET_CLOSE_POPOVER", popoverLocation);
  },
  setCurrentPalletDetails({ commit }, details) {
    commit("SET_CURRENT_PALLET_DETAILS", details);
  },
  setCurrentOperation({ commit }, operation) {
    commit("SET_CURRENT_OPERATION", operation);
  },
  setCurrentPallet({ commit }, pallet) {
    commit("SET_CURRENT_PALLET", pallet);
  },
  openPallet({ commit }) {
    commit("OPEN_PALLET", true);
  },
  openPalletHandled({ commit }) {
    commit("OPEN_PALLET", false);
  },
  async setPalletsInTemporaryLocationWithContent({ commit }, payload) {
    commit("SET_PALLETS_IN_TEMPORARY_LOCATION", payload);
  },
  async setPalletsInTemporaryLocation({ commit }, camera) {
    commit(
      "SET_PALLETS_IN_TEMPORARY_LOCATION",
      await palletsInTemporaryLocation(camera)
    );
  },
  removePalletFromTemporaryLocation({ commit }, palletCode) {
    commit("REMOVE_PALLET_FROM_TEMPORARY_LOCATION", palletCode);
  },
  clearFilters({ commit }) {
    commit("CLEAR_FILTERS");
  },
  setFilterSector({ commit }, sector) {
    commit("SET_FILTER_SECTOR", sector);
  },
  setFilterPalletNumber({ commit }, palletNumber) {
    commit("SET_FILTER_PALLET_NUMBER", palletNumber);
  },
  setFilterProduct({ commit }, product) {
    commit("SET_FILTER_PRODUCT", product);
  },
  setFilterSize({ commit }, size) {
    commit("SET_FILTER_SIZE", size);
  },
  setFilterPresentation({ commit }, presentation) {
    commit("SET_FILTER_PRESENTATION", presentation);
  },
};
