<template>
  <div class="PopoverFreeLocation">
    <div class="PopoverFreeLocation__Actions">
      <b-button variant="outline-primary" size="lg" @click="onMove">Mover</b-button>
      <b-button variant="outline-success" size="lg" @click="onNew">Nuevo</b-button>
    </div>
    <b-modal id="modal-pallets" hide-footer title="Seleccionar pallet" @hide="onCloseModal">
      <PalletsPrompt @palletSelected="selectPallet"/>
    </b-modal>
  </div>
</template>

<script>
import PalletsPrompt from '@/components/Pages/PalletsPrompt.vue'

export default {
  props: [
    'positionX',
    'positionY',
    'sector'
  ],
  components: {
    PalletsPrompt
  },
  methods: {
    onMove() {
      this.$emit('moving')
      this.$bvModal.show('modal-pallets')
    },
    onNew() {
      this.$emit('new')
    },
    selectPallet(palletCode) {
      this.$emit('palletMoved', [palletCode, this.positionX, this.positionY])
    },
    onCloseModal() {
      this.$emit('closedPopup')
    }
  }
}
</script>

<style lang="scss" scoped>
.PopoverFreeLocation {
  padding: .3rem;
  min-width: 220px;

  &__Actions {

    display: flex;
    justify-content: space-between;

  }
}
</style>
