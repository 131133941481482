<template>
  <section class="Pallet">
    <PalletDetails
        v-if="showDetails"
        :show="showDetails"
        :activeProducts="getActiveProducts"
        :defaultLinesData="linesData"
        :disableInputs="disableInputs"
    />
    <b-container fluid>
      <b-form-row>
        <b-col>
          <b-form>
            <b-form-row>
              <b-col sm="8">
                <b-form-group
                    id="NumeroPallet-InputGroup"
                    label="Número de Pallet"
                    label-for="NumeroPallet-Input"
                    :state="palletNumberState"
                    :invalid-feedback="palletNumberFeedback"
                >
                  <b-form-input
                      autofocus
                      :disabled="disableInputs || disableCode"
                      ref="NumeroPallet"
                      id="NumeroPallet-Input"
                      type="text"
                      @blur="handleBlurNumeroPallet"
                      v-model="palletNumber"
                      :state="palletNumberState"
                  />
                </b-form-group>
              </b-col>
              <b-col sm="3" offset-sm="1" class="postion-button-details">
                <b-button :disabled="disableInputs" @click="toggleDetails" variant="secondary" class="mb-2">
                  Detalles
                </b-button>
              </b-col>
            </b-form-row>
            <b-modal id="complex-pallet-content-warning" title="Advertencia"
                     header-bg-variant="light"
                     ok-title="Continuar"
                     cancel-title="Cancelar"
                     @ok="cleanCurrentPalletDetailsData">
              <p class="my-4">Si continúa, se perderán los detalles del pallet</p>
            </b-modal>
            <b-form-row v-if="!showDetails">
              <b-col>
                <b-form-group
                    id="Producto-InputGroup"
                    label="Producto"
                    label-for="Producto-Input"
                >
                </b-form-group>
              </b-col>
            </b-form-row>
            <b-form-row v-if="!showDetails">
              <b-col>
                <b-button
                    @click="handleProductsModal"
                    class="mb-3"
                    :disabled="disableInputs"
                    :variant="productSelected ? 'success' : 'info'"
                >
                  {{ productButtonDescription }}
                </b-button>
              </b-col>
            </b-form-row>
            <b-modal hide-footer id="modal-products" title="Productos">
              <ProductsPrompt @productSelected="selectProduct"/>
            </b-modal>
            <b-form-row v-if="productSelected && !showDetails">
              <b-col sm="4">
                <b-form-group
                    id="Origin-InputGroup"
                    label="Origen"
                    label-for="Origin-Input"
                />
              </b-col>
              <b-col sm="4">
                <b-form-group
                    id="Talle-InputGroup"
                    label="Talle"
                    label-for="Talle-Input"
                >
                </b-form-group>
              </b-col>
              <b-col sm="4">
                <b-form-group
                    id="Presentacion-InputGroup"
                    label="Presentación"
                    label-for="Presentacion-Input"
                />
              </b-col>
            </b-form-row>
            <b-form-row v-if="productSelected && !showDetails">
              <b-col sm="4">
                <b-button
                    @click="showOrigin = true"
                    class="mb-3"
                    :disabled="disableInputs"
                    :variant="originSelected ? 'success' : 'info'"
                >
                  {{ originButtonDescription }}
                </b-button>
              </b-col>
              <b-col sm="4">
                <b-button
                    @click="showSizes = true"
                    class="mb-3"
                    :disabled="disableInputs"
                    :variant="sizeSelected ? 'success' : 'info'"
                >
                  {{ sizeButtonDescription }}
                </b-button>
              </b-col>
              <b-col sm="4">
                <b-button
                    @click="showPresentations = true"
                    class="mb-3"
                    :disabled="disableInputs"
                    :variant="presentationSelected ? 'success' : 'info'"
                >
                  {{ presentationButtonDescription }}
                </b-button>
              </b-col>
            </b-form-row>
            <b-form-row v-if="showOrigin">
              <b-col>
                <b-list-group>
                  <b-list-group-item
                      button
                      v-for="item in getOriginOptions"
                      :key="item.value"
                      @click="selectOrigin(item)"
                      :active="isOriginActive(item)"
                  >
                    {{ item.text }}
                  </b-list-group-item>
                </b-list-group>
              </b-col>
            </b-form-row>
            <b-form-row v-if="showSizes">
              <b-col>
                <b-list-group>
                  <b-list-group-item
                      button
                      v-for="item in getSizeOptions"
                      :key="item.value"
                      @click="selectSize(item)"
                      :active="isSizeActive(item)"
                  >
                    {{ item.text }}
                  </b-list-group-item>
                </b-list-group>
              </b-col>
            </b-form-row>
            <b-form-row v-if="showPresentations">
              <b-col>
                <b-list-group>
                  <b-list-group-item
                      button
                      v-for="item in getPresentationOptions"
                      :key="item.value"
                      @click="selectPresentation(item)"
                      :active="isPresentationActive(item)"
                  >
                    {{ item.text }}
                  </b-list-group-item>
                </b-list-group>
              </b-col>
            </b-form-row>
            <b-form-row v-if="presentationSelected && !showDetails">
              <b-col>
                <b-form-group
                    id="Peso-InputGroup"
                    label="Peso"
                    label-for="Peso-Input"
                >
                  <b-form-row>
                    <b-col sm="3">
											<span class="Pallet__Weight" :class="customWeight?'Pallet__Weight--cancelled':''">
												{{
                          `${this.getPresentationPalletWeight(productSelected.Codigo, presentationSelected.value).PesoPallet} Kg.`
                        }}
											</span>
                    </b-col>
                    <b-col>
                      <b-form-checkbox :disabled="disableInputs" id="Peso-Input" v-model="customWeight" size="lg">
                        Indicar otro
                      </b-form-checkbox>
                    </b-col>
                  </b-form-row>
                </b-form-group>
              </b-col>
            </b-form-row>
            <b-form-row v-if="presentationSelected && customWeight && !showDetails">
              <b-col>
                <b-form-group
                    id="PesoCustom-InputGroup"
                >
                  <b-col class="ml-n3 Pallet__Weight__Input" sm="6">
                    <b-input
                        id="PesoCustom-Input"
                        type="text"
                        class="Pallet__Input"
                        :disabled="disableInputs"
                        v-model="customWeightValue"
                    />
                    <span class="ml-2">Kg.</span>
                  </b-col>
                </b-form-group>
              </b-col>
            </b-form-row>
            <b-form-row v-if="!showDetails">
              <b-col>
                <b-form-group
                    id="FechaProduccion-InputGroup"
                    label="Fecha de producción"
                    label-for="FechaProduccion-Input"
                >
                  <datetime
                      input-id="FechaProduccion-Input"
                      v-model="productionDate"
                      type="date"
                      :disabled="disableInputs"
                      :phrases="{ok: 'Aceptar', cancel: 'Cancelar'}"
                      value-zone="UTC-3"
                      zone="UTC-3"
                      input-class="Pallet__Date"
                  />
                  <span class="Pallet__ProductionNumber">{{ getProductionNumber }}</span>
                </b-form-group>
              </b-col>
            </b-form-row>
            <b-form-row v-if="!showDetails">
              <b-col>
                <b-form-group
                    id="FechaIngreso-InputGroup"
                    label="Fecha de ingreso"
                    label-for="FechaIngreso-Input"
                >
                  <datetime
                      input-id="FechaIngreso-Input"
                      v-model="receptionDate"
                      type="datetime"
                      :disabled="disableInputs"
                      :phrases="{ok: 'Aceptar', cancel: 'Cancelar'}"
                      :flow="['date', 'time']"
                      value-zone="UTC-3"
                      zone="UTC-3"
                      input-class="Pallet__Date"
                  />
                </b-form-group>
              </b-col>
            </b-form-row>
            <b-form-row v-if="!showDetails">
              <b-col>
                <b-form-group
                    id="FechaVencimiento-InputGroup"
                    label="Fecha de vencimiento"
                    label-for="FechaVencimiento-Input"
                >
                  <datetime
                      input-id="FechaVencimiento-Input"
                      v-model="expiryDate"
                      type="date"
                      :disabled="disableInputs"
                      :phrases="{ok: 'Aceptar', cancel: 'Cancelar'}"
                      :flow="['year', 'date']"
                      value-zone="UTC-3"
                      zone="UTC-3"
                      input-class="Pallet__Date"
                  />
                </b-form-group>
              </b-col>
            </b-form-row>
          </b-form>
        </b-col>
      </b-form-row>
      <b-form-row v-if="getCurrent.operation === 'remove'">
        <b-form-group
            id="Destino-InputGroup"
            label="Destino"
            label-for="Destino-Input"
        />
      </b-form-row>
      <b-form-row v-if="getCurrent.operation === 'remove'">
        <b-button
            @click="showDestinations = true"
            class="mb-3"
            :variant="destinationSelected ? 'success' : 'info'"
        >
          {{ destinationButtonDescription }}
        </b-button>
      </b-form-row>
      <b-form-row v-if="showDestinations">
        <b-col>
          <b-list-group>
            <b-list-group-item
                button
                v-for="item in getDestinationOptions"
                :key="item.value"
                @click="selectDestination(item)"
                :active="isDestinationActive(item)"
            >
              {{ item.text }}
            </b-list-group-item>
          </b-list-group>
        </b-col>
      </b-form-row>
      <b-form-row v-if="destinationrRequiresExtraData">
        <b-form-group
            id="DatosExtra-InputGroup"
            :label="destinationExtraDataName"
            label-for="DatosExtra-Input"
        >
          <b-form-input
              id="DatosExtra-Input"
              type="text"
              v-model="destinationExtraData"
          />
        </b-form-group>
      </b-form-row>
      <b-form-row>
        <b-col>
          <b-form-group
              id="LocationGroup"
              label="Ubicación"
              label-for="Location"
          />
        </b-col>
      </b-form-row>
      <b-form-row>
        <b-col>
          <location
              v-if="temporaryLocation === false"
              :key="locationKey"
              :camera="this.getCurrentCamera.Numero"
              :sector="this.getCurrentCamera.Sectores[getPalletLocation.sectorPosition].Nombre"
              :column="getPalletLocation.column"
              :floor="getPalletLocation.floor"
              :position="getPalletLocation.position"
          />
          <h3 v-else>En piso</h3>
        </b-col>
      </b-form-row>
      <b-alert class="mt-3" variant="danger" v-model="showError" :dismissible="true">
        {{ errorMessage }}
      </b-alert>
      <b-form-row class="mt-3 float-right">
        <b-col>
          <b-button variant="outline-danger" @click="handleCancel"
                    :disabled="handlingCheckout || handlingEdit || handlingSave">Cancelar
          </b-button>
          <b-button v-if="getCurrent.operation === 'remove'" :disabled="handlingCheckout" variant="success" class="ml-3"
                    @click="handleCheckout">
            <div v-if="handlingCheckout">
              <b-spinner small type="grow"></b-spinner>
              <span>Procesando...</span>
            </div>
            <span v-else>Dar salida</span>
          </b-button>
          <b-button v-if="getCurrent.operation === 'edit'" :disabled="handlingEdit" variant="success" class="ml-3"
                    @click="handleEdit">
            <div v-if="handlingEdit">
              <b-spinner small type="grow"></b-spinner>
              <span>Procesando...</span>
            </div>
            <span v-else>Editar</span>
          </b-button>
          <b-button v-if="getCurrent.operation === 'insert'" variant="success" :disabled="handlingSave" class="ml-3" @click="handleSave">
            <div v-if="handlingSave">
              <b-spinner small type="grow"></b-spinner>
              <span>Procesando...</span>
            </div>
            <span v-else>Guardar</span>
          </b-button>
        </b-col>
      </b-form-row>
    </b-container>
  </section>
</template>

<script>
import {mapGetters} from 'vuex'
import {Datetime} from 'vue-datetime'
import ProductsPrompt from '@/components/Pages/ProductsPrompt.vue'
import Location from '@/components/Location.vue'
import PalletDetails from '@/components/PalletDetails.vue'
import {calculatePalletPosition, validatePalletData} from '@/utils/palletOperations.js'
import {
  createPallet,
  createPalletDetail,
  existsPalletNumber,
  getDate,
  getDestinations,
  getNextPalletNumber,
  getPallet,
  releasePallet,
  updatePallet,
} from '@/api'

import productionMixin from '@/mixins/productionNumberMixin.js'


export default {
  data() {
    return {
      productSelected: null,
      sizeSelected: null,
      presentationSelected: null,
      showProducts: false,
      showSizes: false,
      showPresentations: false,
      palletNumber: null,
      palletCode: '',
      detailCode: null,
      customWeight: false,
      customWeightValue: 0,
      receptionDate: this.$moment.tz(new Date(), "America/Montevideo").format('YYYY-MM-DDTHH:mm'),
      expiryDate: '',
      showOrigin: false,
      originSelected: null,
      palletNumberBlured: false,
      errorMessage: '',
      showError: false,
      palletNumberFeedback: '',
      invalidPalletNumber: false,
      showDetails: false,
      disableInputs: false,
      linesData: [],
      destinationSelected: null,
      showDestinations: false,
      handlingCheckout: false,
      handlingSave: false,
      posibleDestinations: [],
      destinationExtraData: '',
      disableCode: false,
      handlingEdit: false,
      temporaryLocation: false,
      locationKey: 1,
    }
  },
  components: {
    Datetime,
    Location,
    PalletDetails,
    ProductsPrompt
  },
  mixins: [productionMixin],
  methods: {
    toggleDetails() {
      this.handleToggleDetails(this.showDetails)
    },

    handleToggleDetails(beforeClickShowDetails) {
      if (!beforeClickShowDetails) {
        let validatePallet = validatePalletData(this.palletNumber, this.invalidPalletNumber, this.productSelected, this.originSelected,
            this.sizeSelected, this.presentationSelected, this.customWeight, this.customWeightValue, this.productionDate, this.receptionDate,
            this.expiryDate, 0, this.$moment, false)
        this.linesData = []
        if (validatePallet.success) {
          let onlyRow = this.getSingleProductPalletDetails()
          this.linesData.push(onlyRow)
        }
      } else {
        let linesAdded = this.getCurrentPalletDetails.length
        if (linesAdded > 0) {
          if (linesAdded === 1) {
            this.getOnlyRowPalletDetails()
          } else {
            this.$bvModal.show('complex-pallet-content-warning')
            return
          }
        } else {
          this.clearFields()
        }

      }
      this.showDetails = !this.showDetails
    },

    cleanCurrentPalletDetailsData() {
      this.clearFields()
      this.linesData = []
      this.$store.dispatch("setCurrentPalletDetails", this.linesData)
      this.showDetails = !this.showDetails
    },

    clearFields() {
      this.productSelected = null
      this.sizeSelected = null
      this.presentationSelected = null
      this.showProducts = false
      this.showSizes = false
      this.showPresentations = false
      this.customWeight = false
      this.customWeightValue = 0
      this.receptionDate = this.$moment.tz(new Date(), "America/Montevideo").format('YYYY-MM-DDTHH:mm')
      this.expiryDate = ''
      this.originSelected = null
      this.showOrigin = false
    },

    getOnlyRowPalletDetails() {
      let onlyRow = this.getCurrentPalletDetails[0]
      let productCode = Number.parseInt(onlyRow.Producto.value)
      this.productSelected = this.getActiveProducts.filter(product => Number.parseInt(product.Codigo) === productCode)[0]

      this.detailCode = onlyRow.Codigo || 0

      if (onlyRow.Talle) {
        this.sizeSelected = {
          value: onlyRow.Talle.value,
          text: onlyRow.Talle.text
        }
      }

      if (onlyRow.Presentacion) {
        this.presentationSelected = {
          value: onlyRow.Presentacion.value,
          text: onlyRow.Presentacion.text
        }
      }

      if (onlyRow.Origen) {
        this.originSelected = {
          text: onlyRow.Origen.text,
          value: onlyRow.Origen.value
        }
      }

      this.customWeight = onlyRow.Peso !== 1000
      this.weight = 1000
      this.customWeightValue = this.customWeight ? onlyRow.Peso : 0
      this.productionDate = onlyRow.Produccion
      this.receptionDate = onlyRow.Ingreso
      this.expiryDate = onlyRow.Vencimiento
      this.linesData = []
    },

    getSingleProductPalletDetails() {
      let detailCode = 0
      if (this.getCurrent.pallet.details && this.getCurrent.pallet.details.length > 0 && this.getCurrent.pallet.details[0].Codigo) {
        detailCode = this.getCurrent.pallet.details[0].Codigo
      }

      let originSelected = {}
      if (this.originSelected) {
        originSelected = {
          value: this.originSelected.value,
          text: this.originSelected.text
        }
      }

      let product = {}
      if (this.productSelected !== null) {
        product = {
          value: this.productSelected.Codigo,
          text: this.productSelected.Nombre
        }
      }

      let size = {}
      if (this.sizeSelected) {
        size = {
          value: this.sizeSelected.value,
          text: this.sizeSelected.text
        }
      }

      let presentation = {}
      if (this.presentationSelected) {
        presentation = {
          value: this.presentationSelected.value,
          text: this.presentationSelected.text
        }
      }

      let fechaProduccion = this.$moment.tz(this.productionDate, "America/Montevideo").format('YYYY-MM-DD')
      let fechaIngreso = this.$moment.tz(this.receptionDate, "America/Montevideo").format('YYYY-MM-DDTHH:mm')
      let fechaExpiracion = this.$moment.tz(this.expiryDate, "America/Montevideo").format('YYYY-MM-DDTHH:mm')
      let weight = this.customWeight ? this.customWeightValue : this.weight
      let customWeight = this.customWeight
      let packageWeight = this.getPresentationPalletWeight(this.productSelected.Codigo, this.presentationSelected.value).PesoCaja
      let packages = Math.ceil(weight / packageWeight)

      return {
        Linea: 1,
        Codigo: detailCode,
        Producto: product,
        Origen: originSelected,
        Talle: size,
        Presentacion: presentation,
        Ingreso: fechaIngreso,
        Produccion: fechaProduccion,
        Vencimiento: fechaExpiracion,
        PesoCustom: customWeight,
        Peso: weight,
        Cajas: packages
      }

    },
    handleProductsModal() {
      this.$bvModal.show('modal-products')
    },
    closeModal() {
      this.$emit('close')
    },
    selectProduct(product) {
      this.productSelected = product
      const sizes = this.getSizesOfProduct(this.productSelected.Codigo)
      const presentations = this.getPresentationsOfProduct(this.productSelected.Codigo)
      if (sizes.length === 1) {
        this.sizeSelected = {
          value: sizes[0].Codigo,
          text: sizes[0].Nombre
        }
      } else {
        this.sizeSelected = null
      }
      if (presentations.length === 1) {
        this.presentationSelected = {
          value: presentations[0].Codigo,
          text: presentations[0].Descripcion
        }
      } else {
        this.presentationSelected = null
      }
      this.showProducts = false
      this.$bvModal.hide('modal-products')
    },
    selectSize(size) {
      this.sizeSelected = size
      this.showSizes = false
    },
    isSizeActive(size) {
      if (this.sizeSelected)
        return size.value === this.sizeSelected.value
      else
        return false
    },
    selectPresentation(presentation) {
      this.presentationSelected = presentation
      this.showPresentations = false
    },
    isPresentationActive(presentation) {
      if (this.presentationSelected)
        return presentation.value === this.presentationSelected.value
      else
        return false
    },
    selectOrigin(origin) {
      this.originSelected = origin
      this.showOrigin = false
    },
    isOriginActive(origin) {
      if (this.originSelected)
        return origin.value === this.originSelected.value
      else
        return false
    },
    selectDestination(destination) {
      this.destinationSelected = destination
      this.showDestinations = false
    },
    isDestinationActive(destination) {
      if (this.destinationSelected)
        return destination.value === this.destinationSelected.value
      else
        return false
    },
    async calculatePosition() {
      if (!this.getPalletLocation)
        return
      const pallet = await getPallet(this.palletCode)
      if (pallet && pallet.PosicionTemporal !== null) {
        this.temporaryLocation = true
        this.locationKey ++
      } else {
        const stowageType = this.getCurrentCamera.Sectores[this.getPalletLocation.sectorPosition].TipoEstiba
        let realLocation = calculatePalletPosition(
            stowageType,
            this.getPalletLocation.sectorPosition,
            this.getPalletLocation.column,
            this.getPalletLocation.floor,
            this.getPalletLocation.position
        )
        await this.$store.dispatch("setPalletLocation", realLocation)
      }
    },
    handleCancel() {
      this.$emit('close')
    },
    async handleSave() {
      this.handlingSave = true
      this.errorMessage = ''
      const validatePallet = validatePalletData(
          this.palletNumber,
          this.invalidPalletNumber,
          this.productSelected,
          this.originSelected,
          this.sizeSelected,
          this.presentationSelected,
          this.customWeight,
          this.customWeightValue,
          this.productionDate,
          this.receptionDate,
          this.expiryDate,
          0,
          this.$moment,
          false
      )
      if ((!validatePallet.success && this.getCurrentPalletDetails.length === 0) ||
          (validatePallet.code === 1 || validatePallet.code === 2)) {
        this.showError = true
        this.errorMessage = validatePallet.message
      }
      if (validatePallet.success || this.getCurrentPalletDetails.length > 0) {
        let newPalletLocationCode
        const locations = this.getUbicaciones(this.getPalletLocation.sectorPosition, this.getPalletLocation.column)
        const rowLocations = locations.filter((element) => {
          return element.Piso === this.getPalletLocation.floor
        }).sort((a, b) => {
          if (a.Posicion >= b.Posicion)
            return 1
          else
            return -1
        })
        const stowageType = this.getCurrentCamera.Sectores[this.getPalletLocation.sectorPosition].TipoEstiba
        switch (stowageType) {
          case 'PUSHBACK':
            newPalletLocationCode = rowLocations[0].Codigo
            //await doPushback(rowLocations)
            break;
          case 'PILA':
            for (let i = 0; i < rowLocations.length; i++) {
              if (rowLocations[i].Posicion === this.getPalletLocation.position) {
                newPalletLocationCode = rowLocations[i].Codigo
                break
              }
            }
            break;
        }
        await this.createPallet(newPalletLocationCode)
        
        this.$emit('close')
      }
      this.handlingSave = false
    },
    async handleEdit() {
      this.handlingEdit = true
      this.errorMessage = ''
      const validatePallet = validatePalletData(
          this.palletNumber,
          this.invalidPalletNumber,
          this.productSelected,
          this.originSelected,
          this.sizeSelected,
          this.presentationSelected,
          this.customWeight,
          this.customWeightValue,
          this.productionDate,
          this.receptionDate,
          this.expiryDate,
          0,
          this.$moment,
          false
      )
      if ((!validatePallet.success && this.getCurrentPalletDetails.length === 0) ||
          (validatePallet.code === 1 || validatePallet.code === 2)) {
        this.showError = true
        this.errorMessage = validatePallet.message
      } else {

        let palletUpdatedData = {}
        palletUpdatedData.palletCode = this.palletCode
        palletUpdatedData.customWeightValue = parseInt(this.customWeightValue)
        palletUpdatedData.hasDetails = this.getCurrentPalletDetails && this.getCurrentPalletDetails.length > 0

        palletUpdatedData.details = []

        if (palletUpdatedData.hasDetails) {
          if (this.getCurrentPalletDetails.length > 0) {
            for (let detail of this.getCurrentPalletDetails) {
              let detailItem = {}
              detailItem.code = detail.Codigo
              detailItem.size = detail.Talle.value
              detailItem.weight = detail.Peso
              detailItem.completeWeight = !detail.PesoCustom
              detailItem.presentation = detail.Presentacion.value
              detailItem.receptionDate = detail.Ingreso
              detailItem.productionDate = detail.Produccion
              detailItem.expiryDate = detail.Vencimiento
              detailItem.packages = detail.Cajas
              detailItem.origin = detail.Origen.value
              palletUpdatedData.details.push(detailItem)
            }
          }

        } else {
          // Get first Item from detailsCollection
          let detailItem = {}
          detailItem.code = this.detailCode
          detailItem.weight = this.customWeight ? this.customWeightValue :
              this.getPresentationPalletWeight(this.productSelected.Codigo, this.presentationSelected.value).PesoPallet
          detailItem.completeWeight = this.customWeight === 1
          detailItem.size = this.sizeSelected.value
          detailItem.presentation = this.presentationSelected.value
          detailItem.receptionDate = this.receptionDate
          detailItem.productionDate = this.productionDate
          detailItem.expiryDate = this.expiryDate
          detailItem.origin = this.originSelected.value
          palletUpdatedData.details.push(detailItem)
        }

        await updatePallet(palletUpdatedData)

        const CameraCode = this.getCurrentCamera.Codigo
        await this.$store.dispatch('setCurrentCamera', CameraCode)
        this.$emit('close')
      }
      this.handlingSave = false
    },
    async createPallet(locationCode) {
      let newPallet = await createPallet(
          parseInt(this.palletNumber),
          null,
          locationCode,
          !this.customWeight,
          this.getCurrentPalletDetails.length > 0
      )

      this.palletCode = newPallet.Codigo

      if (this.getCurrentPalletDetails.length === 0) {
        let weight = this.customWeight ? this.customWeightValue :
            this.getPresentationPalletWeight(this.productSelected.Codigo, this.presentationSelected.value).PesoPallet
        let completeWeight = this.customWeight === 1
        await createPalletDetail(
            parseInt(this.palletCode),
            this.sizeSelected.value,
            this.presentationSelected.value,
            this.receptionDate,
            this.productionDate,
            this.expiryDate,
            0, // packages
            parseInt(weight),
            completeWeight,
            this.originSelected.value
        )
      } else {
        for (let i = 0; i < this.getCurrentPalletDetails.length; i++) {
          let element = this.getCurrentPalletDetails[i]
          await createPalletDetail(
              parseInt(this.palletCode),
              element.Talle.value,
              element.Presentacion.value,
              element.Ingreso,
              element.Produccion,
              element.Vencimiento,
              parseInt(element.Cajas),
              element.Peso,
              !element.PesoCustom,
              element.Origen.value
          )
        }
      }
    },
    async handleBlurNumeroPallet() {
      this.invalidPalletNumber = false
      this.palletNumberBlured = true
      if (this.palletNumber.length === 0) {
        this.palletNumberFeedback = 'Debes ingresar un número de pallet.'
      } else {
        let serverDate = await getDate()
        let year = serverDate.getFullYear()
        if (this.palletNumber < 1000000) {
          this.palletNumber = year * 1000000 + this.palletNumber
          this.invalidPalletNumber = false
          this.palletNumberFeedback = ''
        } else {
          let palletNumberString = this.palletNumber.toString()
          if (palletNumberString.substring(0, 4) !== year.toString() || palletNumberString.length !== 10) {
            this.invalidPalletNumber = true
            this.palletNumberFeedback = 'El número de pallet ingresado no es correcto.'
          }
        }
      }
      if (!this.invalidPalletNumber) {
        if (await existsPalletNumber(this.palletNumber)) {
          this.invalidPalletNumber = true
          this.palletNumberFeedback = 'El número de pallet ingresado ya existe.'
        }
      }
    },
    loadPallet() {
      const firstElement = this.getCurrent.pallet.details[0]
      this.palletCode = this.getCurrent.pallet.Codigo
      this.palletNumber = this.getCurrent.pallet.Numero ? this.getCurrent.pallet.Numero : 0
      this.showDetails = this.getCurrent.pallet.TieneDetalle
      const productCode = firstElement.Talle.ProductoCodigo
      let product
      for (let i = 0; i < this.getActiveProducts.length; i++) {
        if (this.getActiveProducts[i].Codigo === productCode) {
          product = this.getActiveProducts[i]
        }
      }
      this.selectProduct(product)
      if (this.getCurrent.pallet.details.length === 0)
        return
      if (!this.getCurrent.pallet.TieneDetalle) {
        this.detailCode = firstElement.Codigo
        this.productionDate = this.$moment.tz(firstElement.FechaProduccion, "America/Montevideo").format('YYYY-MM-DD')
        this.receptionDate = this.$moment.tz(firstElement.FechaIngreso, "America/Montevideo").format('YYYY-MM-DDTHH:mm')
        if (firstElement.FechaVencimiento && firstElement.FechaVencimiento.length > 0) {
          this.expiryDate = this.$moment.tz(firstElement.FechaVencimiento, "America/Montevideo").format('YYYY-MM-DD')
        }
        if (firstElement.Origen === 'IMPORTACION') {
          this.originSelected = {
            value: firstElement.Origen,
            text: 'Importación'
          }
        } else {
          this.originSelected = {
            value: firstElement.Origen,
            text: 'Producción'
          }
        }
        this.sizeSelected = {
          value: firstElement.Talle.Codigo,
          text: firstElement.Talle.Nombre
        }
        this.presentationSelected = {
          value: firstElement.Presentacion.Codigo,
          text: firstElement.Presentacion.Descripcion
        }
        this.customWeight = !firstElement.EsPesoCompleto
        this.customWeightValue = firstElement.Peso
      } else {
        for (let i = 0; i < this.getCurrent.pallet.details.length; i++) {
          let line = this.getCurrent.pallet.details[i]

          let selectedProduct = {}
          for (let product of this.getActiveProducts) {
            if (product.Codigo === line.Talle.ProductoCodigo) {
              selectedProduct = product
            }
          }

          let producto = {
            value: selectedProduct.Codigo,
            text: selectedProduct.Nombre
          }

          let origen = {
            value: line.Origen
          }

          if (line.Origen === 'IMPORTACION') {
            origen.text = 'Importación'
          } else {
            origen.text = 'Producción'
          }

          let talle = {
            value: line.Talle.Codigo,
            text: line.Talle.Nombre
          }

          let presentacion = {
            value: line.Presentacion.Codigo,
            text: line.Presentacion.Descripcion
          }

          this.linesData.push({
            Linea: i + 1,
            Codigo: line.Codigo,
            Producto: producto,
            Origen: origen,
            Talle: talle,
            Presentacion: presentacion,
            Ingreso: line.FechaIngreso,
            Produccion: line.FechaProduccion,
            Vencimiento: line.FechaVencimiento,
            Cajas: line.Cajas,
            PesoCustom: !line.EsPesoCompleto,
            Peso: line.Peso
          })
        }
      }
    },
    disableFields() {
      this.disableInputs = true
    },
    async handleCheckout() {
      if (!this.destinationSelected) {
        this.showError = true
        this.errorMessage = 'Debes seleccionar un destino para la salida del pallet'
        return
      }
      this.handlingCheckout = true
      await releasePallet(this.palletCode, this.destinationSelected.value, this.destinationExtraData)
     
      await this.$store.dispatch('setPalletsInTemporaryLocation', this.getCurrentCamera.Codigo)
      this.handleCheckout = false
      this.$emit('close')
    },

  
    async getPalletNumber() {
      this.palletNumber = await getNextPalletNumber()
    }
  },
  computed: {
    ...mapGetters([
      'getActiveProducts',
      'getSizesOfProduct',
      'getPresentationsOfProduct',
      'getPresentationPalletWeight',
      'getPalletLocation',
      'getCurrentCamera',
      'getUbicaciones',
      'getCurrentPalletDetails',
      'getCurrent'
    ]),
    getProductOptions: function () {
      let opt = []
      for (let i = 0; i < this.getActiveProducts.length; i++) {
        opt.push({
          value: this.getActiveProducts[i].Codigo,
          text: this.getActiveProducts[i].Nombre
        })
      }
      return opt
    },
    productButtonDescription: function () {
      if (this.productSelected)
        return this.productSelected.Nombre
      else
        return 'Seleccionar'
    },
    getSizeOptions: function () {
      let opt = []
      if (this.productSelected) {
        const sizes = this.getSizesOfProduct(this.productSelected.Codigo)
        for (let i = 0; i < sizes.length; i++) {
          opt.push({
            value: sizes[i].Codigo,
            text: sizes[i].Nombre
          })
        }
      }
      return opt
    },
    sizeButtonDescription: function () {
      if (this.productSelected) {
        if (this.sizeSelected) {
          return this.sizeSelected.text
        } else
          return 'Seleccionar'
      } else {
        return ''
      }
    },
    getPresentationOptions: function () {
      let opt = []
      if (this.productSelected) {
        const presentations = this.getPresentationsOfProduct(this.productSelected.Codigo)
        for (let i = 0; i < presentations.length; i++) {
          opt.push({
            value: presentations[i].Codigo,
            text: presentations[i].Descripcion
          })
        }
      }
      return opt
    },
    presentationButtonDescription: function () {
      if (this.productSelected) {
        if (this.presentationSelected) {
          return this.presentationSelected.text
        } else {
          return 'Seleccionar'
        }
      } else {
        return ''
      }
    },
    getOriginOptions: function () {
      let opt = []
      const origins = [{
        Codigo: 'PRODUCCION',
        Descripcion: 'Producción'
      }, {
        Codigo: 'IMPORTACION',
        Descripcion: 'Importación'
      }]
      for (let i = 0; i < origins.length; i++) {
        opt.push({
          value: origins[i].Codigo,
          text: origins[i].Descripcion
        })
      }
      return opt
    },
    getDestinationOptions: function () {
      let opt = []
      for (let i = 0; i < this.posibleDestinations.length; i++) {
        opt.push({
          value: this.posibleDestinations[i].Codigo,
          text: this.posibleDestinations[i].Nombre
        })
      }
      return opt
    },
    originButtonDescription: function () {
      if (this.originSelected) {
        return this.originSelected.text
      } else {
        return 'Seleccionar'
      }
    },
    destinationButtonDescription: function () {
      if (this.destinationSelected) {
        return this.destinationSelected.text
      } else {
        return 'Seleccionar'
      }
    },
    palletNumberState: function () {
      if (this.palletNumberBlured !== true)
        return null
      else {
        return !(this.palletNumber.length === 0 || this.invalidPalletNumber);

      }
    },
    destinationrRequiresExtraData: function () {
      if (this.destinationSelected !== null) {
        let destination = this.posibleDestinations.filter((element) => {
          if (element.Codigo === this.destinationSelected.value)
            return element
        })
        if (destination.length === 1) {
          return destination[0].RequiereDatoExtra
        }
      }
      return false
    },
    destinationExtraDataName: function () {
      if (this.destinationSelected !== null) {
        let destination = this.posibleDestinations.filter((element) => {
          if (element.Codigo === this.destinationSelected.value)
            return element
        })
        if (destination.length === 1) {
          return destination[0].DatoExtraNombre
        }
      }
      return ''
    }
  },
  created() {
    this.$store.dispatch("setCurrentPalletDetails", [])
    if (this.getCurrent) {
      switch (this.getCurrent.operation) {
        case 'remove':
          this.loadPallet()
          this.disableFields()
          break
        case 'edit':
          this.loadPallet()
          this.disableCode = true
          break
        default:
          this.getPalletNumber()
          this.calculatePosition()
          break
      }
    }
  },
  beforeMount() {
    getDestinations().then((results) => {
      this.posibleDestinations = results
    }).catch(() => {
      this.posibleDestinations = []
    })
  }
}
</script>

<style lang="scss" scoped>

.Pallet {

  &__Weight {
    font-size: 1.2rem;

    &--cancelled {
      text-decoration: line-through;
    }

    &__Input {
      display: flex;
      align-items: baseline;
    }

  }
}

.postion-button-details {
  margin-top: 32px;
}

.auxiliaryNumpad {
  position: absolute;
  right: -1366px;
  top: 180px;
  z-index: 10000;
  box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.35);
  border-radius: 5px;
  border-style: solid;
  border-width: 1px;
}
</style>

<style lang="scss">
.Pallet__Date {
  height: 2.5rem;
  border-radius: .25rem;
  padding-left: 10px;

  &:disabled {
    border: 1px solid #ced4da;
  }
}

.Pallet__ProductionNumber {
  position: absolute;
  right: 4rem;
  top: 2.5rem;
}

</style>
