export default {
	data() {
		return {
			productionDate: this.$moment.tz(new Date(), "America/Montevideo").format('YYYY-MM-DD'),
		}
	},
	computed: {
		getProductionNumber: function () {
			if (this.productionDate && this.productionDate.length > 0) {
				let year = this.$moment.tz(this.productionDate, "America/Montevideo").year()
				let dayOfYear = this.$moment.tz(this.productionDate, "America/Montevideo").dayOfYear()
				return `${year}-${dayOfYear}`
			} else {
				return ''
			}
		}
	}
}