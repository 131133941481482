<template>
  <div>
    <main>
      <navigation v-if="this.$store.getters.user.loggedIn" />
      <div class="app__Content">
        <router-view/>
      </div>
      <versionLabel/>
    </main>
  </div>
</template>

<script>
import Navigation from '@/components/Navigation.vue'
import VersionLabel from '@/components/VersionLabel.vue'

export default {
  components: {
    Navigation,
    VersionLabel
  }
}
</script>

<style lang="scss">
  // sets the rem size of the app
  body, html {
    font-size: 16px;
    font-family: 'Ubuntu', sans-serif !important;
    background-color: #ECECEC !important;
    scroll-behavior: smooth;
  }

  .app__Content {
    min-height: 90vh;
  }

  .page-header {
    padding-bottom: 9px;
    margin: 40px 0 20px;
    border-bottom: 1px solid #eee;
  }

  h1 {
    margin-top: 20px;
    margin-bottom: 10px;
  }
</style>