<template>
  <div id="element-container" class="SectorElement" :key="customKey">
    <span
      v-if="!inverted"
      class="SectorElement__Number bg-primary text-light"
      >{{ elementNumber }}</span
    >
    <div
      class="SectorElement__Row"
      v-for="elem in floors.slice().reverse()"
      :key="elem"
    >
      <div
        v-b-tooltip.hover
        @click="handleElementClick(item, elem)"
        :title="getPositionTitle(item, elem)"
        :class="getBackground(item, elem)"
        class="SectorElement__Position"
        :id="`location-${sector}-${elementNumber}-${item}-${elem}`"
        :style="getProductStyle(item, elem)"
        v-for="item in depth"
        :key="item"
      >
        <b-popover
          :target="`location-${sector}-${elementNumber}-${item}-${elem}`"
          :ref="`popover-${sector}-${elementNumber}-${item}-${elem}`"
          placement="auto"
          container="element-container"
          triggers=""
        >
          <PopoverContent
            v-if="!locationFree"
            :locationCode="getLocationCodeForPopover(item, elem)"
          />
          <PopoverFreeLocation
            v-else
            :positionX="item"
            :positionY="elem"
            @new="handleNew(item, elem)"
            @closedPopup="hidePopover = false"
            @moving="handleMoving"
            @palletMoved="handlePalletMoved"
          />
        </b-popover>
      </div>
    </div>
    <span v-if="inverted" class="SectorElement__Number bg-primary text-light">{{
      elementNumber
    }}</span>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import PopoverContent from "@/components/PopoverContent.vue";
import PopoverFreeLocation from "@/components/PopoverFreeLocation.vue";
import { findLocationCodeByMetadata, movePallet } from "@/api";
import {
  calculatePalletPosition,
} from "@/utils/palletOperations.js";
import { calculatePalletColors } from "@/utils/calculatePalletColors.js";
import { getPositionLetter } from "@/utils/newPalletPosition.js";


export default {
  props: [
    "elementNumber",
    "floors",
    "depth",
    "inverted",
    "locations",
    "sector",
  ],
  data() {
    return {
      locationFree: false,
      customKey: 0,
    };
  },
  components: {
    PopoverContent,
    PopoverFreeLocation,
  },
  methods: {
    getPositionTitle(x, y) {
      return `Piso: ${y} / Posición: ${getPositionLetter(x)}`;
    },
    getBackground(x, y) {
      let cssClass = "bg-info";
      const element = this.getElement(x, y);
      const status = element.Estado;
      if (status === "LIBRE") cssClass = "bg-success";
      return cssClass;
    },
    getElementStatus(x, y) {
      const element = this.getElement(x, y);
      if (element) return element.Estado;
      return "";
    },
    getProductStyle(x, y) {
      const elem = this.getElement(x, y);
      if (this.getFilterPalletNumber) {
        if (elem) {
          if (elem.Pallet) {
            if (elem.Pallet.Numero === this.getFilterPalletNumber) {
              return "background-color: #F00 !important; box-shadow: 0px 5px 15px 0px rgba(255,0,0,1);";
            }
          }
        }
        return "background-color: #777 !important; pointer-events: none;";
      } else {
        if (this.getFilterProduct) {
          let sizes = [];
          if (this.getFilterSize) {
            sizes.push({ Codigo: this.getFilterSize });
          } else {
            sizes = this.getSizesOfProduct(this.getFilterProduct);
          }
          if (elem) {
            if (elem.Pallet) {
              if (elem.Pallet.Detalles.length > 0) {
                let hasProduct = false;
                for (let i = 0; i < elem.Pallet.Detalles.length; i++) {
                  let detail = elem.Pallet.Detalles[i];
                  if (detail.TalleCodigo && detail.TalleCodigo > 0) {
                    const filteredSize = sizes.find((elem) => {
                      return elem.Codigo === detail.TalleCodigo;
                    });
                    hasProduct = filteredSize !== undefined;
                    if (hasProduct) {
                      if (this.getFilterPresentation) {
                        if (
                          detail.PresentacionCodigo ===
                          this.getFilterPresentation
                        ) {
                          break;
                        } else {
                          hasProduct = false;
                        }
                      } else {
                        break;
                      }
                    }
                  }
                }
                if (hasProduct) {
                  let colors = calculatePalletColors(elem.Pallet.Detalles);
                  switch (colors.length) {
                    case 0:
                      return "";
                    case 1:
                      return `background-color: #${colors[0]} !important`;
                    default:
                      return `background: repeating-linear-gradient(45deg, #${colors[0]}, #${colors[0]} 10px, #${colors[1]} 10px,	#${colors[1]} 20px) !important;`;
                  }
                } else {
                  return "background-color: #777 !important; pointer-events: none;";
                }
              }
            } else {
              return "background-color: #777 !important; pointer-events: none;";
            }
          }
        } else {
          if (elem) {
            if (elem.Pallet) {
              if (elem.Pallet.Detalles.length > 0) {
                let colors = calculatePalletColors(elem.Pallet.Detalles);
                switch (colors.length) {
                  case 0:
                    return "";
                  case 1:
                    return `background-color: #${colors[0]} !important`;
                  default:
                    return `background: repeating-linear-gradient(45deg, #${colors[0]}, #${colors[0]} 10px, #${colors[1]} 10px,	#${colors[1]} 20px) !important;`;
                }
              }
            }
          }
        }
        return "";
      }
    },
    getElement(x, y) {
      if (this.locations) {
        for (let i = 0; i <= this.locations.length; i++) {
          let element = this.locations[i];
          if (element && element.Posicion === x && element.Piso === y) {
            return element;
          }
        }
      }
      return null;
    },
    handleElementClick(x, y) {
      let currentPopover = this.$store.getters.getCurrentPopover;
      if (currentPopover != null) {
        if (this.$refs[currentPopover]) {
          this.$refs[currentPopover][0].$emit("close");
          this.$store.dispatch("togglePopover", null);
          this.$store.dispatch("setClosePopover", null);
        } else {
          this.$store.dispatch("setClosePopover", currentPopover);
        }
      }
      setTimeout(() => {
        const status = this.getElementStatus(x, y);
        if (status === "LIBRE") {
          this.locationFree = true;
          if (this.getPalletsInTemporaryLocation.length > 0) {
            const ref = `popover-${this.sector}-${this.elementNumber}-${x}-${y}`;
            if (this.$refs[ref][0].localShow) {
              this.$refs[ref][0].$emit("close");
              this.$store.dispatch("togglePopover", null);
            } else {
              this.$refs[ref][0].$emit("open");
              this.$store.dispatch("togglePopover", ref);
            }
          } else {
            const elementData = {
              Posicion: x,
              Piso: y,
              Columna: this.elementNumber,
            };
            this.$store.dispatch("setCurrentOperation", "insert");
            this.$emit("elementClick", elementData);
          }
        } else {
          this.locationFree = false;
          /* we set the location in the store in case the user
							uses one of the popover actions	*/
          const location = {
            position: x,
            floor: y,
            column: this.elementNumber,
            sectorPosition: this.sector,
          };
          this.$store.dispatch("setPalletLocation", location);
          /* -------------------------------------------------- */
          const ref = `popover-${this.sector}-${this.elementNumber}-${x}-${y}`;
          if (this.$refs[ref][0].localShow) {
            this.$refs[ref][0].$emit("close");
            this.$store.dispatch("togglePopover", null);
          } else {
            this.$refs[ref][0].$emit("open");
            this.$store.dispatch("togglePopover", ref);
          }
        }
      }, 150);
    },
    handleNew(x, y) {
      let currentPopover = this.$store.getters.getCurrentPopover;
      if (currentPopover != null) {
        this.$store.dispatch("setClosePopover", currentPopover);
      }
      setTimeout(() => {
        const elementData = {
          Posicion: x,
          Piso: y,
          Columna: this.elementNumber,
        };
        this.$store.dispatch("setCurrentOperation", "insert");
        this.$emit("elementClick", elementData);
      }, 150);
    },
    handleMoving() {
      this.hidePopover = true;
    },
    async handlePalletMoved(parms) {
      let palletCode = parms[0];
      let x = parms[1];
      let y = parms[2];
      const stowageType = this.getCurrentCamera.Sectores[this.sector]
        .TipoEstiba;
      let position = calculatePalletPosition(
        stowageType,
        this.sector,
        this.elementNumber,
        y,
        x
      );

      this.$store.dispatch("removePalletFromTemporaryLocation", parms[0]);

      setTimeout(async () => {
        const sectorCode = this.getCurrentCamera.Sectores[this.sector].Codigo;
        let locationCode = await findLocationCodeByMetadata(
          sectorCode,
          position.column,
          position.floor,
          position.position
        );
       
        await movePallet(palletCode, locationCode, this.getCurrentCamera.Codigo);
        this.$store.dispatch("setCurrentCamera", this.getCurrentCamera.Codigo);
      }, 150);

      let currentPopover = this.$store.getters.getCurrentPopover;
      this.$refs[currentPopover][0].$emit("close");
      this.$store.dispatch("togglePopover", null);
    },
    getLocationCodeForPopover(x, y) {
      const el = this.getElement(x, y);
      if (el) return el.Codigo;
      else return 0;
    },
  },
  computed: {
    ...mapGetters({
      getPalletsInTemporaryLocation: "getPalletsInTemporaryLocation",
      getUbicaciones: "getUbicaciones",
      getCurrentCamera: "getCurrentCamera",
      getFilterPalletNumber: "getFilterPalletNumber",
      getFilterProduct: "getFilterProduct",
      getPopoverToClose: "getPopoverToClose",
      getSizesOfProduct: "getSizesOfProduct",
      getFilterSize: "getFilterSize",
      getFilterPresentation: "getFilterPresentation",
    }),
  },
  watch: {
    getFilterPalletNumber() {
      this.customKey += 1;
    },
    getFilterProduct() {
      this.customKey += 1;
    },
    getPopoverToClose: function(popoverToCloseLocation) {
      if (
        popoverToCloseLocation != null &&
        this.$refs[popoverToCloseLocation]
      ) {
        const popoverToClose = this.$refs[popoverToCloseLocation];
        popoverToClose[0].$emit("close");
        this.$store.dispatch("setClosePopover", null);
        this.$store.dispatch("openPalletHandled");
      }
    },
  },
};
</script>

<style lang="scss">
.SectorElement {
  margin: 10px;
  display: inline-block;
  text-align: center;

  &__Position {
    width: 60px;
    height: 60px;
    display: inline-block;
    margin: 7px;
    border-radius: 4px;
    cursor: pointer;
  }

  &__Number {
    cursor: default;
    font-size: 1.3rem;
    text-align: center;
    display: inline-block;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    margin-bottom: 5px;
  }
}
</style>
