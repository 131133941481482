import Vue from 'vue'
import firebase from 'firebase/app'
import VueNativeSock from 'vue-native-websocket'
import 'firebase/auth'
import { credentials } from '@/firebase/credentials.js'
import BootstrapVue from 'bootstrap-vue'
import VueMoment from 'vue-moment'
import moment from 'moment-timezone'
import { Settings } from 'luxon'
import App from './App.vue'
import { store } from '@/store'
import router from '@/routes'


// spanish translation for datetime inputs
Settings.defaultLocale = 'es'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import 'vue-datetime/dist/vue-datetime.css'

Vue.config.productionTip = false
firebase.initializeApp(credentials)
Vue.use(BootstrapVue)
Vue.use(VueMoment, {
  moment,
})

const websocketUrl = process.env.VUE_APP_SERVER_WS_ENDPOINT
const websocketsOptions = {
  reconnection: true,
  reconnectionDelay: 3000,
  store
}

Vue.use(VueNativeSock, websocketUrl, websocketsOptions)


new Vue({
  store,
  router,
  render: h => h(App),
}).$mount('#app')
