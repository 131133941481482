import { store } from '@/store'
import jwt from 'jsonwebtoken';
import firebase from 'firebase/app';
import 'firebase/auth'

export async function changePassword(userOldPassword, userNewPassword) {
	//obtengo las credenciales del usuario
	var user = firebase.auth().currentUser;
	var credential = firebase.auth.EmailAuthProvider.credential(
		user.email,
		userOldPassword
	)

	var result = ''
	
	try {
		await user.reauthenticateWithCredential(credential).then(async function() {
			await user.updatePassword(userNewPassword).then(async function() {
				result = 'OK'
			}).catch(function() {
				result = 'Error al cambiar la contraseña'
			});
		}).catch(function() {
			result = 'Error al autenticar el usuario'
		});
	} catch(_) {
		result = 'Error al autenticar el usuario'
	}

	return result
}

export async function validAuth() {
	var token = store.getters.user.idToken
	var decoded = jwt.decode(token)
	var expDate = new Date(0)
	expDate.setUTCSeconds(decoded.exp)
	
	if (expDate < new Date()) {
		await firebase.auth().currentUser.getIdToken(true).then(function(idToken) {
			store.dispatch("setRefreshToken", {idToken: idToken})
		}).catch(function(e) {
			// eslint-disable-next-line
			console.error(e)
		});
	}
}
