<template>
	<div class="Login">
		<div class="Login__Form">
			<div class="Login__FormContainer">
				<div class="Login__Avatar">
					<img src="/avatar.png" alt="Avatar">
				</div>
        <h2 class="text-center">Recuperación de Contraseña</h2>
				<div v-if="error" class="alert alert-danger">{{error}}</div>
				<b-form-group>
					<b-form-input 
					class="Login__Form__Input" 
					autofocus autocomplete="on" 
					type="email" 
					id="email" 
					v-model="email" 
					required="required" 
					placeholder="Email"
					@keyup="checkSubmit"
					/>
				</b-form-group>
				<b-form-group>
            <b-button @click="submit" variant="primary" block>Recuperar</b-button>
        </b-form-group>
				<div class="clearfix">
					<router-link
						to="/Login"
						class="pull-right"
						activeClass="pull-right" 
					>
					volver
					</router-link>
				</div>
			</div>
		</div>
		
	</div>
</template>

<script>
import firebase from 'firebase/app';
import 'firebase/auth'
import { mapGetters } from 'vuex'
export default {
	
	data: () => {
		return {
			email: '',
			error: ''
		}
	}, 
	methods: {
		submit() {
			if (!this.mailIsValid)
				return this.error = 'El email no es correcto' 
			firebase.auth().sendPasswordResetEmail(this.email)
				.then(() => {
					this.error = 'Si el correo ingresado se encuentra registrado se enviará un correo para restablecer la contraseña'
					//this.$router.replace({ path: "/login" })
				})
				.catch(err => {
					this.parseAuthError(err.code)
				});
				},
		checkSubmit(e) {
			if (e.keyCode === 13)
				this.submit()
		},
		parseAuthError(code) {
			switch(code) {
				case 'auth/user-not-found':
					this.error = 'Si el correo ingresado se encuentra registrado se enviará un correo para restablecer la contraseña' 
					break;
			}
		}
	},
	computed: {
		mailIsValid: function () {
			// eslint-disable-next-line
			const regex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
			return regex.test(this.email)
		},
		...mapGetters([
			'user'
		])
	}
}
</script>

<style lang="scss" scoped>
	.Login {
		background-image: linear-gradient(to right, rgba(0,0,255,0.2), rgba(0,0,255,0.5));
		width: 100vw;
		height: 100vh;

		&__Form {
			width: 350px;
			margin-right: 350px;
			margin-left: calc(100% - 700px);
			padding: 150px 0 30px;

			&Container{
				color: #7a7a7a;
				border-radius: 2px;
				margin-bottom: 15px;
        font-size: 13px;
        background: #ececec;
        box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
        padding: 30px;	
        position: relative;
			}

			& h2 {
				font-size: 22px;
        margin: 35px 0 25px;
			}
			
			&__Input {
				font-size: 20px;
			}

			& button {
				font-size: 20px;
			}

			& a {
				color: #7a7a7a;
				text-decoration: none;
				font-size: 15px;
				float: right;

				&:hover {
					text-decoration: underline;
				}
			}
		}

		&__Avatar {
			position: absolute;
			margin: 0 auto;
			left: 0;
			right: 0;
			top: -50px;
			width: 95px;
			height: 95px;
			border-radius: 50%;
			z-index: 9;
			background: #70c5c0;
			padding: 15px;
			box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);

			& img {
				width: 100%
			}
		}
	}


</style>