<template>
  <div style="min-height: 85vh;">
    <b-container>
      <b-row style="padding-top: 30px">
        <b-col cols="12">
          <b-card-group deck>
            <b-card
                class="MenuCard"
                border-variant="primary"
                header="Cámaras"
                header-tag="span"
                header-bg-variant="primary"
                header-text-variant="white"
                align="center"
                v-b-modal="'CameraSelector'"
            >
              <b-card-text>Selecciona la cámara en la cuál quieres trabajar</b-card-text>
            </b-card>

            <b-card
                class="MenuCard"
                @click="showWarehouse"
                :border-variant="getCardColor(getCurrentCamera, 'border')"
                header="Ver bodega"
                header-tag="span"
                :header-bg-variant="getCardColor(getCurrentCamera, 'background')"
                header-text-variant="white"
                align="center"
                :class="getCardColor(getCurrentCamera)"
            >
              <b-card-text>Visualiza la bodega de la cámara activa</b-card-text>
            </b-card>
            <b-card
                class="MenuCard primary"
                @click="showPalletWW"
                border-variant="primary"
                header="Trabajar con Pallets"
                header-tag="span"
                header-bg-variant="primary"
                header-text-variant="white"
                align="center"
            >
              <b-card-text>Permite visualizar todos los pallets y su información asociada</b-card-text>
            </b-card>
          </b-card-group>
        </b-col>
      </b-row>
    </b-container>
    <b-modal
        centered
        id="CameraSelector"
        aria-label="Selección de cámara"
    >
      <template v-slot:modal-header="{ close }">
        <CloseBar title="Cámaras" @close="close()"/>
      </template>
      <b-container fluid>
        <b-row>
          <b-col cols="12">
            <CameraSelector @close="hideCameraSelector"/>
          </b-col>
        </b-row>
      </b-container>

      <template v-slot:modal-footer>
        <div></div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'
import CameraSelector from '@/components/Pages/CameraSelector.vue'
import CloseBar from '@/components/CloseBar.vue'

export default {
  components: {
    CameraSelector,
    CloseBar
  },
  methods: {
    hideCameraSelector() {
      this.$bvModal.hide('CameraSelector')
    },
    showWarehouse() {
      this.$router.push({
        name: 'Warehouse'
      })
    },
    showPalletWW() {
      this.$router.push({
        name: 'PalletWW'
      })
    },
    getCardColor(camera, target) {
      if (!camera && !target) {
        return 'MenuCard--disabled'
      }
      if (camera && target)
        return 'primary'
      return 'secondary'
    }
  },
  computed: {
    ...mapGetters([
      'getCameras',
      'getCurrentCamera'
    ])
  },
  beforeCreate() {
    this.$store.dispatch('fetchCameraList')
    this.$store.dispatch('fetchProductList')
  }
}
</script>

<style lang="scss" scoped>
.MenuCard {
  max-width: 25rem;
  cursor: pointer;

  & span {
    font-size: 1.3rem;
  }

  &--disabled {
    cursor: default;
  }
}
</style>
