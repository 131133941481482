export const calculatePalletColors = (palletDetails)  => {
	let colors = []
	let found = false
	for (let i = 0; i < palletDetails.length ; i++) {
		if (palletDetails[i].Talle) {
			if (palletDetails[i].Talle.Producto) {
				if (palletDetails[i].Talle.Producto.Color) {
					let color = palletDetails[i].Talle.Producto.Color
					found = false
					for (let j = 0; j < colors.length; j++) {
						if (colors[j] ===  color){
							found = true 
							break
						}
					}
					if (!found) 
						colors.push(color)
				}
			}
		}
	}
	return colors
}