<template>
  <div class="Warehouse" v-if="this.showSectores()">
    <div
      class="Warehouse__SideElement"
      :class="showSideElement ? 'Warehouse__SideElement--show' : ''"
    >
      <CloseBar :title="sideElementTitle" @close="showSideElement = false" />
      <b-container fluid class="my-4">
        <b-row>
          <b-col cols="12">
            <Pallet
              :key="modalKey"
              @close="showSideElement = false"
              v-if="showSideElement === true"
            />
          </b-col>
        </b-row>
      </b-container>
    </div>
    <div :class="showSideElement ? 'Warehouse__Overlay' : ''" />
    <WarehouseFilters />
    <WarehouseSector
      v-if="this.showSector(0)"
      :sectorPosition="0"
      @elementClick="showPallet"
    />
    
    <StagingSector :cameraCodigo="parseInt(getCurrentCamera.Codigo)" />
    <WarehouseSector
      v-if="this.showSector(1)"
      :sectorPosition="1"
      inverted="true"
      @elementClick="showPallet"
    />
  </div>
</template>

<script>
import WarehouseSector from "@/components/WarehouseSector.vue";
import StagingSector from "@/components/StagingSector.vue";
import Pallet from "@/components/Pages/Pallet.vue";
import CloseBar from "@/components/CloseBar.vue";
import WarehouseFilters from "@/components/WarehouseFilters.vue";

import { mapGetters } from "vuex";

export default {
  data() {
    return {
      modalKey: 0,
      showSideElement: false,
    };
  },
  components: {
    WarehouseSector,
    StagingSector,
    Pallet,
    CloseBar,
    WarehouseFilters,
  },
  methods: {
    showSectores() {
      return this.getCurrentCamera && this.getCurrentCamera.Sectores; // &&
      // this.getCurrentCamera.Sectores.length === 2)
    },
    showPallet(data) {
      if (data) {
        const palletLocation = {
          column: data.Columna,
          floor: data.Piso,
          position: data.Posicion,
          sectorPosition: data.sectorPosition,
        };
        this.$store.dispatch("setPalletLocation", palletLocation);
      }
      this.modalKey = this.modalKey + 1;
      this.showSideElement = true;
    },
    showSector(i) {
      return this.getCurrentCamera.Sectores &&
        this.getCurrentCamera.Sectores.length == 0
        ? false
        : this.getCurrentCamera.Sectores[i].Codigo === this.getFilterSector ||
            this.getFilterSector === null;
    },
  },
  computed: {
    ...mapGetters([
      "getCurrentCamera",
      "getOpenPallet",
      "getCurrent",
      "getFilterSector",
    ]),
    sideElementTitle: function() {
      if (this.getCurrent.operation === "remove") {
        return "Salida Pallet";
      } else {
        return "Ingreso Pallet";
      }
    },
  },
  beforeMount() {
    this.$store.dispatch("setCurrentCamera", this.getCurrentCamera.Codigo);
  },
  watch: {
    getOpenPallet: function(newValue) {
      if (newValue === true) {
        this.showPallet();
        this.$store.dispatch("openPalletHandled");
      }
    },
  },
};
</script>

<style lang="scss">
.Warehouse {
  position: relative;

  &__SideElement {
    width: 0;
    background-color: white;
    position: absolute;
    box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.35);
    transition: all 0.15s;
    overflow: hidden;
    margin-top: -2rem;

    &--show {
      padding: 1rem;
      width: 500px;
      overflow: initial;
      z-index: 101;
    }
  }

  &__Overlay {
    z-index: 100;
    background-color: rgba(0, 0, 0, 0.45);
    width: 100vw;
    height: 100vh;
    position: fixed;
    margin-top: -2rem;
  }
}
</style>
