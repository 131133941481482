<template>
	<b-container class="DuplicationForm">
		<b-form>
			<b-form-row>
				<b-col sm="8">
					<b-form-group
						id="NumeroPallet-InputGroup"
						label="Número de Pallet"
						label-for="NumeroPallet-Input"
						:state="palletNumberState"
						:invalid-feedback="palletNumberFeedback"
					>
						<b-form-input
						autofocus
						ref="NumeroPallet"
						id="NumeroPallet-Input"
						type="text"
						@blur="handleBlurNumeroPallet"
						v-model="palletNumber"
						:state="palletNumberState"
						/>
					</b-form-group>
				</b-col>
			</b-form-row>
			<b-form-row class="mt-3 float-right">
				<b-col>
					<b-button variant="outline-danger" @click="handleCancel">Cancelar</b-button>
					<b-button variant="success" class="ml-3" @click="handleSave">
						<span>Guardar</span>
					</b-button>
				</b-col>
			</b-form-row>
		</b-form>
	</b-container>
</template>

<script>
import { existsPalletNumber, getNextPalletNumber, getDate } from '@/api'
export default {
	data () {
		return {
			palletNumber: null,
			palletNumberFeedback: '',
			palletNumberBlured: false,
			invalidPalletNumber: false
		}
	},
	computed: {
		palletNumberState () {
			if (this.palletNumberBlured !== true)
				return null
			else {
				if (this.palletNumber.length === 0 || this.invalidPalletNumber) {
					return false
				}
				return true
			}
		}
	},
	methods: {
		async handleBlurNumeroPallet () {
			this.invalidPalletNumber = false
			this.palletNumberBlured = true
			if (this.palletNumber.length === 0) {
				this.palletNumberFeedback = 'Debes ingresar un número de pallet.'
			} else {
				const serverDate = await getDate()
				let year = serverDate.getFullYear()
				if (this.palletNumber < 1000000) {
					this.palletNumber = year * 1000000 + this.palletNumber
					this.invalidPalletNumber = false
					this.palletNumberFeedback = ''
				} else {
					let palletNumberString = this.palletNumber.toString()
					if (palletNumberString.substring(0, 4) !== year.toString() || palletNumberString.length !== 10) {
						this.invalidPalletNumber = true
						this.palletNumberFeedback = 'El número de pallet ingresado no es correcto.'
					}
				}
			}
			if (!this.invalidPalletNumber) {
				if (await existsPalletNumber(this.palletNumber)) {
					this.invalidPalletNumber = true
					this.palletNumberFeedback = 'El número de pallet ingresado ya existe.'
				}
			}
		},
		handleSave () {
			if (!this.invalidPalletNumber) {
				this.$emit('save', this.palletNumber)
			}
		},
		handleCancel () {
			existsPalletNumber()
			this.$emit('cancel')
		},
		async getPalletNumber() {
			this.palletNumber = await getNextPalletNumber()
		}
	},
	created () {
		this.getPalletNumber()
	}
}
</script>

<style>

</style>