import { store } from '@/store'

export const validatePalletData = (
	palletNumber, 
	duplicatedPalletNumber,
	productCode,
	originCode,
	sizeCode,
	presentationCode,
	customWeight,
	customWeightValue,
	productionDate,
	receptionDate,
	expiryDate,
	packages,
	moment,
	isDetail
	) => {
	let returnValue = {
		success: true,
		message: '',
		code: 0
	}
	if (!isDetail) {
		const cleanPalletNumber = parseInt(palletNumber, 10)
		if (Number.isNaN(cleanPalletNumber)) {
			returnValue.message = 'El código de pallet no es un número válido'
			returnValue.success = false
			returnValue.code = 1
		}
		if (returnValue.success) {
			if (duplicatedPalletNumber) {
				returnValue.message = 'El código de pallet ya existe'
				returnValue.success = false
				returnValue.code = 2
			}
		}
	}
	if (returnValue.success || isDetail) {
		if (productCode === null) {
			returnValue.message = 'Debes seleccionar un producto'
			returnValue.success = false
			returnValue.code = 3
		}
	}
	if (returnValue.success) {
		if (originCode === null) {
			returnValue.message = 'Debes seleccionar un origen'
			returnValue.success = false
			returnValue.code = 4
		}
	}
	if (returnValue.success) {
		if (sizeCode === null) {
			returnValue.message = 'Debes seleccionar un talle'
			returnValue.success = false
			returnValue.code = 5
		}
	}
	if (returnValue.success) {
		if (presentationCode === null) {
			returnValue.message = 'Debes seleccionar una presentación'
			returnValue.success = false
			returnValue.code = 6
		}
	}
	if (returnValue.success && customWeight === true) {
		if (customWeightValue <= 0) {
			returnValue.message = 'Debes especificar el peso del pallet'
			returnValue.success = false
			returnValue.code = 7
		}
	}
	if (returnValue.success) {
		const today = moment.tz(new Date(), "America/Montevideo").format('YYYY-MM-DD')
		if (moment(today).isBefore(moment(productionDate))) {
			returnValue.message = 'La fecha de producción no puede ser mayor a hoy'
			returnValue.success = false
			returnValue.code = 8
		}
	}
	if (returnValue.success) {
		const now = moment.tz(new Date(), "America/Montevideo").format('YYYY-MM-DDTHH:mm')
		if (moment(now).isBefore(moment(receptionDate))) {
			returnValue.message = 'La fecha/hora de ingreso no puede ser mayor a la actual'
			returnValue.success = false
			returnValue.code = 9
		}
	}
	if (returnValue.success) {
		if (expiryDate.length > 0) {
			const today = moment.tz(new Date(), "America/Montevideo").format('YYYY-MM-DD')
			if (moment(today).isAfter(moment(expiryDate))) {
				returnValue.message = 'La fecha de vencimiento no puede ser menor a la actual'
				returnValue.success = false
				returnValue.code = 10
			}
		}
	}
	if (returnValue.success && isDetail) {
		if (packages === 0) {
			returnValue.message = "Debes indicar la cantidad de cajas"
			returnValue.success = false
			returnValue.code = 11
		}
	}
	return returnValue
}

export const calculatePalletPosition = (
	stowageType,
	sectorPosition,
	column,
	floor,
	position
) => {
	const locations = store.getters.getUbicaciones(sectorPosition, column)
	let rowLocations = []
	let realPosition = {}
	let realLocation = {
		column,
		position,
		floor,
		sectorPosition
	}
	switch(stowageType) {
		case 'PUSHBACK':
			// find the row locations sorted by position
			rowLocations = locations.filter((element) => {
				return element.Piso === realLocation.floor
			}).sort((a, b) => {
				if (a.Posicion >= b.Posicion) 
					return 1
				else
					return -1
			})
			// we get the first row location no matter what
			realPosition = rowLocations.find((element) => {
				return element.Posicion === 1
			})
			// save the real position on store
			realLocation.position = realPosition.Posicion
			return realLocation
		case 'PILA':
			return realLocation
	}
}