<template>
  <div class="WarehouseSector">
    <span>{{ this.getCurrentCamera.Sectores[this.sectorPosition].Nombre }}</span>
    <SectorElement class="WarehouseSector__Element"
                   v-for="col in getSectorColumns()" :key="col"
                   :elementNumber="col"
                   :floors="getSectorFloors()"
                   :depth="getSectorDepth()"
                   :inverted="inverted"
                   :locations="getLocations(col)"
                   :sector="sectorPosition"
                   @elementClick="handleElementClick"
    />
  </div>
</template>

<script>
import SectorElement from '@/components/SectorElement.vue'
import {mapGetters} from 'vuex'

export default {
  props: [
    'sectorPosition',
    'inverted'
  ],
  components: {
    SectorElement
  },
  methods: {
    getSectorColumns() {
      return this.getCurrentCamera.Sectores[this.sectorPosition].Columnas
    },
    getSectorFloors() {
      return this.range(this.getCurrentCamera.Sectores[this.sectorPosition].Pisos)
    },
    getSectorDepth() {
      return this.range(this.getCurrentCamera.Sectores[this.sectorPosition].Profundidad)
    },
    range(size, startAt = 1) {
      return [...Array(size).keys()].map(i => i + startAt);
    },
    getLocations(col) {
      return this.getUbicaciones(this.sectorPosition, col)
    },
    handleElementClick(elementData) {
      elementData.sectorPosition = this.sectorPosition
      this.$emit('elementClick', elementData)
    }
  },
  computed: {
    ...mapGetters([
      'getUbicaciones',
      'getCurrentCamera'
    ]),
  }
}
</script>

<style lang="scss" scoped>
.WarehouseSector {
  border: 1px solid black;
  border-radius: 5px;
  margin: 2rem;
  position: relative;
  padding-top: 30px;

  & span {
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
    background-color: white;
    top: -23px;
    font-size: 30px;
    padding: 0 7px;
    border: 1px solid black;
    border-radius: 3px;
  }

}
</style>
