export const state = {
  user: {
    loggedIn: false,
    profileData: null,
    idToken: null,
  },
  socket: {
    isConnected: false,
    message: "",
    reconnectError: false,
  },
  products: [],
  cameras: [],
  currentCamera: null,
  palletLocation: {
    sectorPosition: 0,
    column: 0,
    floor: 0,
    position: 0,
  },
  currentPopover: null,
  popoverToClose: null,
  currentPalletDetails: [],
  palletsInTemporaryLocation: [],
  //--------------------//
  current: {
    operation: "",
    pallet: null,
  },
  eventOpenPallet: false,
  filterSector: null,
  filterPalletNumber: null,
  filterProduct: null,
  filterSize: null,
  filterPresentation: null,
};
