<template>
	<div class="PalletsPrompt">
		<div class="PalletsPrompt__Element bg-info"
				v-for="pallet in getPalletsInTemporaryLocation"
				:key="pallet.Codigo"
				@click="handleClick(pallet.Codigo)"
			>
				{{pallet.Numero}}	
			</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex' 
export default {
	methods: {
		handleClick(palletCode) {
			this.$emit('palletSelected', palletCode)
		}
	},
	computed: {
		...mapGetters([
			'getPalletsInTemporaryLocation'
		])
	}
}
</script>

<style lang="scss" scoped>
	.PalletsPrompt {
		display: flex;
		flex-wrap: wrap;

		&__Element {
			width: auto;
			height: 60px;
			display: inline-block;
			margin: 7px;
			padding: 0 10px;
			border-radius: 4px;
			cursor: pointer;
			display: flex;
			justify-content: center;
			align-items: center;
			color: white;
			font-size: 1.2rem;
			font-weight: 700;
		}
	}

</style>