import Vue from 'vue'
import Router from 'vue-router'
import {store} from '@/store'
import firebase from 'firebase/app';
import 'firebase/auth'

import Login from '@/components/Pages/Login.vue'
import Recover from '@/components/Pages/RecoverPassword.vue'
import Home from '@/components/Pages/Home.vue'
import UserProfile from '@/components/Pages/UserProfile.vue'
import ChangePassword from '@/components/Pages/ChangePassword.vue'
import Warehouse from '@/components/Pages/Warehouse.vue'
import NotFound from '@/components/Pages/NotFound.vue'
import PalletWW from '@/components/Pages/PalletWW.vue'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.VUE_APP_BASE_URL,
  routes: [
    {
      path: '/Login',
      name: 'Login',
      component: Login
    },
    {
      path: '/RecoverPassword',
      name: 'Recover',
      component: Recover
    },
    {
      beforeEnter: requireAuth,
      path: '/Home',
      name: 'Home',
      alias: '/',
      component: Home
    },
    {
      beforeEnter: requireAuth,
      path: '/Perfil',
      name: 'UserProfile',
      component: UserProfile
    },
    {
      beforeEnter: requireAuth,
      path: '/CambioPasword',
      name: 'ChangePassword',
      component: ChangePassword
    },
    {
      beforeEnter: requireAuth,
      path: '/Bodega',
      name: 'Warehouse',
      component: Warehouse
    },
    {
      beforeEnter: requireAuth,
      path: '/TCPallet',
      name: 'PalletWW',
      component: PalletWW
    },
    {
      path: '/*',
      name: 'NotFound',
      component: NotFound
    }
  ]
})

function requireAuth(to, from, next) {
  firebase.auth().onAuthStateChanged(function (user) {
    if (!user) {
      store.dispatch("setUserLoggedOut")
      next({
        path: "/Login"
      })
    } else {
      firebase.auth().currentUser.getIdToken().then(function (idToken) {
        store.dispatch("setUserLogged", {user: user, idToken: idToken})
        next()
      }).catch(function () {
      });
    }
  })
}

export default router
