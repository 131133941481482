<template>
  <div class="">
    <div class="StagingSector bg-warning" :key="customKey">
      <div class="StagingSector__Element bg-info" :style="getProductStyle(pallet)"
           v-for="pallet in getPalletsInTemporaryLocation"
           :key="pallet.Codigo"
           :id="`pallet-${pallet.Codigo}`"
           @click="handleClick(pallet.Codigo)"
      >
        {{ pallet.Numero }}
        <b-popover
            :target="`pallet-${pallet.Codigo}`"
            :ref="`pallet-${pallet.Codigo}-popover`"
            placement="auto"
            container="element-container"
        >
          <PopoverOnTemporary :palletNumber="parseInt(pallet.Numero)" :palletCode="parseInt(pallet.Codigo)"/>

        </b-popover>
      </div>
    </div>
    <span class="StagingSector__Title">
			área temporal
		</span>
  </div>

</template>

<script>
import {mapGetters} from 'vuex'
import PopoverOnTemporary from '@/components/PopoverOnTemporary.vue'
import {calculatePalletColors} from "@/utils/calculatePalletColors";

export default {
  props: {
    cameraCodigo: Number
  },
  components: {
    PopoverOnTemporary
  },
  data() {
    return {
      temporaryPallets: [],
      customKey: 0,
    }
  },
  methods: {
    handleClick(palletCode) {
      const ref = `pallet-${palletCode}-popover`
      const currentPopover = this.$store.getters.getCurrentPopover
      if (currentPopover != null && ref !== currentPopover) {
         this.$store.dispatch('setClosePopover', currentPopover)
      }
      if (this.$refs[ref][0].localShow) {
        this.$refs[ref][0].$emit('close')
        this.$store.dispatch('togglePopover', null)
      } else {
        this.$refs[ref][0].$emit('open')
        this.$store.dispatch('togglePopover', ref)
      }
    },
    getProductStyle(pallet) {
      if (pallet) {
        if (this.getFilterPalletNumber) {
          if (pallet.Numero !== null && pallet.Numero && pallet.Numero.trim() === this.getFilterPalletNumber.trim()) {
            return "background-color: #F00 !important; box-shadow: 0px 5px 15px 0px rgba(255,0,0,1);"
          } else {
            return "background-color: #777 !important;pointer-events: none;"
          }
        } else {
          if (this.getFilterProduct) {
            let sizes = [];
            if (this.getFilterSize) {
              sizes.push({Codigo: this.getFilterSize});
            } else {
              sizes = this.getSizesOfProduct(this.getFilterProduct);
            }
            if (pallet.Detalles && pallet.Detalles.length > 0) {
              let hasProduct = false;
              for (let i = 0; i < pallet.Detalles.length; i++) {
                let detail = pallet.Detalles[i];
                if (detail.TalleCodigo && detail.TalleCodigo > 0) {
                  const filteredSize = sizes.find((elem) => {
                    return elem.Codigo === detail.TalleCodigo
                  })
                  hasProduct = filteredSize !== undefined
                  if (hasProduct) {
                    if (this.getFilterPresentation) {
                      if (
                          detail.PresentacionCodigo ===
                          this.getFilterPresentation
                      ) {
                        break
                      } else {
                        hasProduct = false
                      }
                    } else {
                      break
                    }
                  }
                }
              }
              if (hasProduct) {
                let colors = calculatePalletColors(pallet.Detalles);
                switch (colors.length) {
                  case 0:
                    return ""
                  case 1:
                    return `background-color: #${colors[0]} !important`
                  default:
                    return `background: repeating-linear-gradient(45deg, #${colors[0]}, #${colors[0]} 10px, #${colors[1]} 10px,	#${colors[1]} 20px) !important;`
                }
              } else {
                return "background-color: #777 !important;pointer-events: none;"
              }
            } else {
              return "background-color: #777 !important;pointer-events: none;"
            }
          }
        }
      }
    }
  },
  computed: {
    ...mapGetters({
      getPalletsInTemporaryLocation: 'getPalletsInTemporaryLocation',
      getPopoverToClose: 'getPopoverToClose',
      getFilterPalletNumber: "getFilterPalletNumber",
      getFilterProduct: "getFilterProduct",
      getFilterSize: "getFilterSize",
      getFilterPresentation: "getFilterPresentation",
      getSizesOfProduct: "getSizesOfProduct"
    })
  },
  watch: {
    getPopoverToClose: function (popoverToCloseLocation) {
      if (popoverToCloseLocation != null && this.$refs[popoverToCloseLocation]) {
        this.$refs[popoverToCloseLocation][0].$emit('close')
        this.$store.dispatch('setClosePopover', null)
        this.$store.dispatch('openPalletHandled')
      }
    },
    getFilterPalletNumber() {
      this.customKey += 1;
    },
    getFilterProduct() {
      this.customKey += 1;
    }
  }
}
</script>

<style lang="scss" scoped>
.StagingSector {
  min-height: 100px;
  height: auto;
  width: calc(100% - 30px);
  padding: 10px;
  margin: 25px 15px;
  margin-bottom: 5px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  &__Title {
    text-align: center;
    display: block;
    margin-bottom: 15px;
    font-size: 1.3rem;
  }

  &__Element {
    width: auto;
    height: 60px;
    margin: 7px;
    padding: 0 7px;
    border-radius: 4px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 1.2rem;
    font-weight: 700;
  }
}
</style>
