import axios from 'axios'
import {store} from '@/store'
import {validAuth} from '@/firebase/utils'

export const findLocationCodeByMetadata = async (sectorCodigo, column, floor, position) => {
  await validAuth()
  const token = store.getters.user.idToken
  const baseUrl = process.env.VUE_APP_SERVER_ENDPOINT
  try {
    const request = await axios.request({
      url: `${baseUrl}/api/location/byMetadata/${sectorCodigo}/${floor}/${column}/${position}`,
      method: 'get',
      responseType: 'json',
      headers: {
        'Authorization': `Bearer ${token}`
      }
    })
    return request.data.Codigo
  } catch (e) {
    //eslint-disable-next-line
    console.error(e)
    return null
  }
}

export const createPallet = async (PalletNumero, Destino, UbicacionCodigo, EsPesoCompleto, TieneDetalle) => {
  await validAuth()
  const token = store.getters.user.idToken
  const baseUrl = process.env.VUE_APP_SERVER_ENDPOINT
  try {
    const request = await axios.post(`${baseUrl}/api/pallet`,
      {
        palletNumber: PalletNumero,
        locationCode: UbicacionCodigo,
        completeWeight: EsPesoCompleto,
        hasDetails: TieneDetalle
      },
      {
        responseType: 'json',
        headers: {
          'Authorization': `Bearer ${token}`
        }
      }
    )
    return request.data
  } catch (e) {
    //eslint-disable-next-line
    console.error(e)
    return null
  }
}

export const createPalletDetail = async (
PalletCodigo,
TalleCodigo,
PresentacionCodigo,
FechaIngreso,
FechaProduccion,
FechaVencimiento,
Cajas,
Peso,
EsPesoCompleto,
Origen) => {
  await validAuth()
  const token = store.getters.user.idToken
  const baseUrl = process.env.VUE_APP_SERVER_ENDPOINT
  try {
    const request = await axios.post(`${baseUrl}/api/palletDetail`,
      {
        PalletCodigo,
        TalleCodigo,
        PresentacionCodigo,
        FechaIngreso,
        FechaProduccion: FechaProduccion.substring(0, 10),
        FechaVencimiento: FechaVencimiento.length > 0 ? FechaVencimiento.substring(0, 10) : null,
        Cajas,
        Peso,
        Origen,
        EsPesoCompleto
      },
      {
        responseType: 'json',
        headers: {
          'Authorization': `Bearer ${token}`
        }
      }
    )
    return request.data
  } catch (e) {
    //eslint-disable-next-line
    console.error(e)
    return null
  }
}

export const getPalletByLocation = async (UbicacionCodigo) => {
  await validAuth()
  const token = store.getters.user.idToken
  const baseUrl = process.env.VUE_APP_SERVER_ENDPOINT
  try {
    const response = await axios.request({
      url: `${baseUrl}/api/pallet/byLocation/${UbicacionCodigo}`,
      method: 'get',
      responseType: 'json',
      headers: {
        'Authorization': `Bearer ${token}`
      }
    })
    return response.data
  } catch (e) {
    //eslint-disable-next-line
    console.error(e)
    return null
  }
}

export const getPallet = async (palletCodigo) => {
  await validAuth()
  const token = store.getters.user.idToken
  const baseUrl = process.env.VUE_APP_SERVER_ENDPOINT
  try {
    const palletCodigoNumber = parseInt(palletCodigo)
    if (isNaN(palletCodigoNumber)) {
      return null
    }
    const response = await axios.request({
      url: `${baseUrl}/api/pallet/byCode/${palletCodigoNumber}`,
      method: 'get',
      responseType: 'json',
      headers: {
        'Authorization': `Bearer ${token}`
      }
    })
    return response.data
  } catch (e) {
    //eslint-disable-next-line
    console.error(e)
    return null
  }
}

export const existsPalletNumber = async (palletNumber) => {
  if (!palletNumber) {
    return false
  }
  await validAuth()
  const token = store.getters.user.idToken
  const baseUrl = process.env.VUE_APP_SERVER_ENDPOINT
  try {
    const response = await axios.get(
      `${baseUrl}/api/pallet/existsPalletByNumber/${palletNumber}`,
      {
        headers: {
          'Authorization': `Bearer ${token}`
        },
        responseType: 'json'
      }
    )
    return response.data.exists
  } catch (error) {
    return false
  }
}

export const getDate = async () => {
  await validAuth()
  const token = store.getters.user.idToken
  const baseUrl = process.env.VUE_APP_SERVER_ENDPOINT

  try {
    const response = await axios.get(
      `${baseUrl}/api/now`,
      {
        headers: {
          'Authorization': `Bearer ${token}`
        },
        responseType: 'json'
      }
    )
    return new Date(response.data.serverDate)

  } catch (requestError) {
    return new Date()
  }

}

export const getNextPalletNumber = async () => {
  await validAuth()
  const token = store.getters.user.idToken
  const baseUrl = process.env.VUE_APP_SERVER_ENDPOINT

  try {
    const response = await axios.get(
      `${baseUrl}/api/pallet/getNextNumber`,
      {
        headers: {
          'Authorization': `Bearer ${token}`
        },
        responseType: 'json'
      }
    )
    return response.data.palletNumber

  } catch (requestError) {
    return 0
  }
}

export const updatePallet = async (palletUpdatedData) => {
  await validAuth()
  const token = store.getters.user.idToken
  const baseUrl = process.env.VUE_APP_SERVER_ENDPOINT

  let requestPayload = {}
  requestPayload.palletData = {}
  requestPayload.palletData.Codigo = palletUpdatedData.palletCode
  requestPayload.palletData.Number = palletUpdatedData.palletNumber
  requestPayload.palletData.EsPesoCompleto = !palletUpdatedData.customWeight
  requestPayload.palletData.TieneDetalle = palletUpdatedData.hasDetails

  requestPayload.palletDetails = []

  for (let detailItem of palletUpdatedData.details) {
    let detailToUpdate = {}
    detailToUpdate.Codigo = detailItem.code
    detailToUpdate.FechaIngreso = detailItem.receptionDate
    detailToUpdate.FechaProduccion = detailItem.productionDate
    detailToUpdate.FechaVencimiento = detailItem.expiryDate
    detailToUpdate.Cajas = detailItem.packages
    detailToUpdate.TalleCodigo = detailItem.size
    detailToUpdate.PresentacionCodigo = detailItem.presentation
    detailToUpdate.PalletCodigo = palletUpdatedData.palletCode
    detailToUpdate.Origen = detailItem.origin
    detailToUpdate.Peso = detailItem.weight
    detailToUpdate.EsPesoCompleto = detailItem.completeWeight
    requestPayload.palletDetails.push(detailToUpdate)
  }

  try {
    const response = await axios.put(
      `${baseUrl}/api/pallet`,
      requestPayload,
      {
        headers: {
          'Authorization': `Bearer ${token}`
        },
        responseType: 'json'
      }
    )

    return response.data

  } catch (requestError) {
    // eslint-disable-next-line no-console
    console.log(requestError)
  }
}

export const movePallet = async (palletCodigo, locationCode, destinationCamera) => {
  await validAuth()
  const token = store.getters.user.idToken
  const baseUrl = process.env.VUE_APP_SERVER_ENDPOINT

  let requestPayload = {}
  requestPayload.palletCodigo = palletCodigo
  requestPayload.locationCode = locationCode
  requestPayload.cameraCode = destinationCamera

  try {
    const response = await axios.post(
      `${baseUrl}/api/pallet/move`,
      requestPayload,
      {
        headers: {
          'Authorization': `Bearer ${token}`
        },
        responseType: 'json'
      }
    )

    return response.data
  } catch (requestError) {
    // eslint-disable-next-line no-console
    console.error(requestError)
  }
}

export const releasePallet = async (PalletCodigo, DestinoCodigo, DatoExtra) => {
  await validAuth()
  const token = store.getters.user.idToken
  const baseUrl = process.env.VUE_APP_SERVER_ENDPOINT
  try {
    const response = await axios.post(
      `${baseUrl}/api/pallet/release`,
      {
        palletCode: parseInt(PalletCodigo),
        destinationCode: DestinoCodigo,
        extraData: DatoExtra
      },
      {
        headers: {
          'Authorization': `Bearer ${token}`
        },
        responseType: 'json'
      }
    )
    return response.data
  } catch (e) {
    //eslint-disable-next-line
    console.error(e)
    return null
  }

}

export const getPalletDetails = async (PalletCodigo) => {
  await validAuth()
  const token = store.getters.user.idToken
  const baseUrl = process.env.VUE_APP_SERVER_ENDPOINT
  const request = await axios.request({
    url: `${baseUrl}/api/palletDetail/ByPallet/${PalletCodigo}`,
    method: 'get',
    responseType: 'json',
    headers: {
      'Authorization': `Bearer ${token}`
    }
  })
  return request.data
}

export const palletsInTemporaryLocation = async (cameraCodigo) => {
  await validAuth()
  const token = store.getters.user.idToken
  const baseUrl = process.env.VUE_APP_SERVER_ENDPOINT
  const request = await axios.request({
    url: `${baseUrl}/api/pallet/getInTemporaryLocation/${cameraCodigo}`,
    method: 'get',
    responseType: 'json',
    headers: {
      'Authorization': `Bearer ${token}`
    }
  })
  for (let i = 0; i < request.data.length; i++) {
    const pallet = request.data[i]
    pallet.Detalles = await getPalletDetails(pallet.Codigo)
  }

  return request.data
}

export const getPallets = async (palletNumber, product, receptionDateFrom, receptionDateTo, producedFrom, producedTo, destinationCode, size, presentation, active) => {
  await validAuth()
  const token = store.getters.user.idToken
  const baseUrl = process.env.VUE_APP_SERVER_ENDPOINT
  const request = await axios.request({
    url: `${baseUrl}/api/pallet`,
    method: 'get',
    params: {
      palletNumber,
      product,
      from: receptionDateFrom,
      to: receptionDateTo,
      producedFrom,
      producedTo,
      destination: destinationCode,
      size,
      presentation,
      active
    },
    responseType: 'json',
    headers: {
      'Authorization': `Bearer ${token}`
    }
  })
  return request.data
}

export const getPalletsByPage = async (palletNumber, product, receptionDateFrom, receptionDateTo, producedFrom, producedTo, destinationCode, size, presentation, active, currentPage, perPage) => {
  await validAuth()
  const token = store.getters.user.idToken
  const baseUrl = process.env.VUE_APP_SERVER_ENDPOINT
  const request = await axios.request({
    url: `${baseUrl}/api/pallet/byPage`,
    method: 'get',
    params: {
      palletNumber,
      product,
      from: receptionDateFrom,
      to: receptionDateTo,
      producedFrom,
      producedTo,
      destination: destinationCode,
      size,
      presentation,
      active,
      currentPage,
      perPage
    },
    responseType: 'json',
    headers: {
      'Authorization': `Bearer ${token}`
    }
  })
  return request.data
}

export const getPalletsExport = async (palletId, product, receptionDateFrom, receptionDateTo, producedFrom, producedTo, destinationCode, size, presentation, active) => {
  await validAuth()
  const token = store.getters.user.idToken
  const baseUrl = process.env.VUE_APP_SERVER_ENDPOINT
  const response = await axios.request({
    url: `${baseUrl}/api/pallet/export`,
    method: 'get',
    params: {
      palletCode: palletId,
      product,
      from: receptionDateFrom,
      to: receptionDateTo,
      producedFrom,
      producedTo,
      destination: destinationCode,
      size,
      presentation,
      active
    },
    responseType: 'blob',
    headers: {
      'Authorization': `Bearer ${token}`
    }
  })

  var fileURL = window.URL.createObjectURL(new Blob([response.data]))
  var fileLink = document.createElement('a')
  fileLink.href = fileURL;
  fileLink.setAttribute('download', 'Valystock-Pallet_export.xlsx')
  document.body.appendChild(fileLink)
  fileLink.click()
  fileLink.remove()
}

export const getProducts = async () => {
  await validAuth()
  const token = store.getters.user.idToken
  const baseUrl = process.env.VUE_APP_SERVER_ENDPOINT
  const request = await axios.request({
    url: `${baseUrl}/api/product`,
    method: 'get',
    responseType: 'json',
    headers: {
      'Authorization': `Bearer ${token}`
    }
  })
  return request.data
}

export const getDestinations = async () => {
  await validAuth()
  const token = store.getters.user.idToken
  const baseUrl = process.env.VUE_APP_SERVER_ENDPOINT
  const request = await axios.request({
    url: `${baseUrl}/api/destination`,
    method: 'get',
    responseType: 'json',
    headers: {
      'Authorization': `Bearer ${token}`
    }
  })
  return request.data
}

export const getSizes = async (id) => {
  await validAuth()
  const token = store.getters.user.idToken
  const baseUrl = process.env.VUE_APP_SERVER_ENDPOINT
  const request = await axios.request({
    url: id ? `${baseUrl}/api/size/${id}` : `${baseUrl}/api/size`,
    method: 'get',
    responseType: 'json',
    headers: {
      'Authorization': `Bearer ${token}`
    }
  })
  return request.data
}

export const getSizesByProduct = async (productId) => {
  await validAuth()
  const token = store.getters.user.idToken
  const baseUrl = process.env.VUE_APP_SERVER_ENDPOINT
  const request = await axios.request({
    url: `${baseUrl}/api/size/byproduct/${productId}`,
    method: 'get',
    responseType: 'json',
    headers: {
      'Authorization': `Bearer ${token}`
    }
  })
  return request.data
}

export const getPresentationsByProduct = async (productId) => {
  await validAuth()
  const token = store.getters.user.idToken
  const baseUrl = process.env.VUE_APP_SERVER_ENDPOINT
  const request = await axios.request({
    url: `${baseUrl}/api/presentation/byproduct/${productId}`,
    method: 'get',
    responseType: 'json',
    headers: {
      'Authorization': `Bearer ${token}`
    }
  })
  return request.data
}

export const duplicatePallet = async (palletCode, newPalletNumber, cameraCode) => {
  await validAuth()
  const token = store.getters.user.idToken
  const baseUrl = process.env.VUE_APP_SERVER_ENDPOINT
  const response = await axios.post(
    `${baseUrl}/api/pallet/duplicate`,
    {
      palletCode: parseInt(palletCode),
      palletNumber: parseInt(newPalletNumber),
      camera: cameraCode
    },
    {
      headers: {
        'Authorization': `Bearer ${token}`
      },
      responseType: 'json'
    }
  )
  return response.data
}
