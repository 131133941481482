<template>
  <div>
    <div  v-if="lettersFilterVisible" class="letters-filters-list">
      <div class="letters-filters-list-item" v-for="(item, index) in this.letters" :key="index" @click="filterProducts(item)">
        {{item}}
      </div>
    </div>
    <b-list-group id="products-list" v-if="!lettersFilterVisible">
      <b-list-group-item class="products-list-item" 
          v-for="(filteredProduct, index) in filteredProducts" 
          :key="index">

          <div class="products-list-item-actions-container"> 
              <b-button pill variant="outline-success" @click="selectProduct(filteredProduct, index)">Seleccionar</b-button>
          </div>
          <div class="products-list-item-product-container">  
            <span>{{filteredProduct.Nombre}}</span>
          </div>
      </b-list-group-item>
      <b-link class="go-back-link" href="#" @click="showInitialFilters">Atrás</b-link>
    </b-list-group>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  data() {
    return {
			filteredProducts: [],
			selectedProduct: "",
			selectedListIndex: "",
			lettersFilterVisible: ""
		}
  },
  computed: {
		...mapGetters([
			'getActiveProducts'
		]),
    letters() {
      const removeAccents = require('remove-accents')
      let letters = this.getActiveProducts.reduce( (letters, currentProduct) => {
        if (!letters.includes(removeAccents(currentProduct.Nombre[0])))
          letters.push(removeAccents(currentProduct.Nombre[0]))
        return letters
      }, []);
      return letters.sort()
    }
  },
  methods: {
    filterProducts: function (letter) {
      const removeAccents = require('remove-accents')
			this.lettersFilterVisible = false
			this.filteredProducts = this.getActiveProducts.filter((product) => {
				return removeAccents(product.Nombre[0]) === removeAccents(letter)
			})
    },
    selectProduct: function (product, index) {
			this.$emit('productSelected', product)
      this.selectedProduct = product
      this.selectedListIndex = index
    },
    showInitialFilters: function ()  {
      this.lettersFilterVisible = true
    },
  },
  mounted() {
    this.filteredProducts = this.getActiveProducts
    this.lettersFilterVisible = true
  }
}
</script>


<style scoped>

  .letters-filters-list {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    width: 50%;
    margin: auto;
    cursor: pointer;
  }

  .letters-filters-list-item {
    border: 1px solid #07F;
    color: #07F;
    border-radius: 10px;
    font-size: 2.5rem;
    width: 4rem;
    height: 4rem;
    text-align: center;
    margin-right: .5rem;
    margin-bottom: .5rem;
  }

    .letters-filters-list-item:hover {
    color: #FFF;
    background-color: #07F;
  }

  .products-list {
    width: 80%;
    margin: auto;
    text-align: center
  }

  .products-list-item div {
    display: inline-block;
  }

  .products-list-item-actions-container {
    width: 50%;
  }

  .products-list-item-product-container {
    width: 50%;
  }

  .go-back-link {
    text-align: right;
    margin-top: 1rem;
  }

</style>
