<template>
  <div>
    <div>
      <h2>Seleccionar cámara</h2>
      <div class="p-3 CamaraSelector__Actions">
        <b-button
            v-for="camera in getCameras"
            :key="camera.Codigo"
            variant="outline-primary"
            size="lg"
            class="CamaraSelector__Actions__Button"
            @click="setCurrentCamera(camera.Codigo)"
        >
          {{ `Cámara ${camera.Numero}` }}
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'


export default {
  props: {
    defaultBehaviour: {
      type: Boolean,
      default: true,
    }
  },
  methods: {
    closeModal() {
      this.$emit('close')
    },
    async setCurrentCamera(codigo) {
      if (this.defaultBehaviour) {
        await this.$store.dispatch('setPalletsInTemporaryLocation', codigo)
        await this.$store.dispatch('setCurrentCamera', codigo)
      } else {
        this.$emit('selected-camera', codigo)
        // await moveTemporaryFromCamera(this.palletCode, codigo)
        // await this.$store.dispatch('setPalletsInTemporaryLocation', codigo)
        // await this.$store.dispatch('setCurrentCamera', codigo)
      }
      this.$emit('close')
    }
  },
  computed: {
    ...mapGetters(['getCameras'])
  },
  beforeCreate() {
    this.$store.dispatch('fetchCameraList')
  }
}
</script>

<style lang="scss" scoped>
.CamaraSelector__Actions {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;

  &__Button {
    flex-shrink: 0;
    margin: 20px 10px;
    font-size: 2rem;
  }
}
</style>
