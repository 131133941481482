<template>
  <div class="WarehouseFilters">
    <b-button @click="visible = true" variant="info" v-if="!visible"
      >Ver filtros</b-button
    >
    <b-button @click="visible = false" variant="info" v-else
      >Ocultar filtros</b-button
    >
    <div class="WarehouseFilters__Title">
      <span>
        <h4 v-if="activeFilters.length > 0">Filtros activos:</h4>
        {{ activeFilters }}
      </span>
    </div>
    <b-collapse :visible="visible" class="mt-2">
      <b-card>
        <h3>Filtros</h3>
        <b-form-row>
          <b-col sm="2" offset-sm="1">
            <b-form-group
              id="Sector-InputGroup"
              label="Sector"
              label-for="Sector-Input"
            >
              <b-form-select
                size="lg"
                id="Sector-Input"
                v-model="sectorSelected"
                :options="sectorOptions"
              ></b-form-select>
            </b-form-group>
          </b-col>
          <b-col sm="2">
            <b-form-group
              id="Pallet-InputGroup"
              label="Número Pallet"
              label-for="Pallet-Input"
            >
              <b-form-input
                id="Pallet-Input"
                type="text"
                v-model="palletNumber"
                size="lg"
              />
            </b-form-group>
          </b-col>
          <b-col sm="2">
            <b-form-group
              id="Product-InputGroup"
              label="Producto"
              label-for="Product-Input"
            >
              <b-form-select
                size="lg"
                id="Product-Input"
                v-model="productSelected"
                :options="productOptions"
              ></b-form-select>
            </b-form-group>
          </b-col>
          <b-col sm="2">
            <b-form-group
              id="size-InputGroup"
              label="Talle"
              label-for="size-Input"
            >
              <b-form-select
                id="size-Input"
                v-model="sizeSelected"
                size="lg"
                :disabled="this.productSelected === null"
                :options="sizeOptions"
              ></b-form-select>
            </b-form-group>
          </b-col>
          <b-col sm="2">
            <b-form-group
              id="presentation-InputGroup"
              label="Presentación"
              label-for="presentation-Input"
            >
              <b-form-select
                id="presentation-Input"
                v-model="presentationSelected"
                size="lg"
                :disabled="this.productSelected === null"
                :options="presentationOptions"
              ></b-form-select>
            </b-form-group>
          </b-col>
        </b-form-row>
      </b-card>
    </b-collapse>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { getSizesByProduct, getPresentationsByProduct } from "@/api";

export default {
  data() {
    return {
      visible: false,
      sectorSelected: null,
      palletNumber: 0,
      productSelected: null,
      sizeSelected: null,
      presentationSelected: null,
      presentationOptions: [{ value: null, text: "[Todos]" }],
      sizeOptions: [{ value: null, text: "[Todos]" }],
    };
  },
  methods: {
    getProductName: function() {
      return this.getActiveProducts.find((elem) => {
        return elem.Codigo === this.productSelected;
      }).Nombre;
    },
    getSizeName: function() {
      const elem = this.sizeOptions.find((elem) => {
        return elem.value === this.sizeSelected;
      })
      return (elem ? elem.text : '');
    },
    getPresentationName: function() {
      const elem = this.presentationOptions.find((elem) => {
        return elem.value === this.presentationSelected;
      })
      return (elem? elem.text : '');
    },
    async getPresentations() {
      const opt = [];
      if (this.productSelected) {
        const colPresentations = await getPresentationsByProduct(
          this.productSelected
        );
        for (let i = 0; i < colPresentations.length; i++) {
          const element = colPresentations[i];
          opt.push({ value: element.Codigo, text: element.Descripcion });
        }
      }
      opt.unshift({ value: null, text: "[Todos]" });
      this.presentationOptions = opt;
    },
    async getSizes() {
      const opt = [];
      if (this.productSelected) {
        const colSizes = await getSizesByProduct(this.productSelected);
        for (let i = 0; i < colSizes.length; i++) {
          const element = colSizes[i];
          opt.push({ value: element.Codigo, text: element.Nombre });
        }
        opt.unshift({ value: null, text: "[Todos]" });
        this.sizeOptions = opt;
      }
    },
  },
  computed: {
    ...mapGetters([
      "getCurrentCamera",
      "getFilterPalletNumber",
      "getFilterSector",
      "getActiveProducts",
      "getFilterProduct",
      "getFilterSize",
      "getFilterPresentation",
    ]),
    sectorOptions: function() {
      let opt = [{ value: null, text: "Todos" }];
      for (let i = 0; i < this.getCurrentCamera.Sectores.length; i++) {
        opt.push({
          value: this.getCurrentCamera.Sectores[i].Codigo,
          text: this.getCurrentCamera.Sectores[i].Nombre,
        });
      }
      return opt;
    },
    productOptions: function() {
      let opt = [];
      for (let i = 0; i < this.getActiveProducts.length; i++) {
        opt.push({
          value: this.getActiveProducts[i].Codigo,
          text: this.getActiveProducts[i].Nombre,
        });
      }
      opt.sort((a, b) => {
        return a.text.localeCompare(b.text);
      });
      opt.unshift({ value: null, text: "Todos" });
      return opt;
    },
    activeFilters: function() {
      let label = "";
      if (this.sectorSelected) {
        let sector = this.getCurrentCamera.Sectores.filter((elem) => {
          if (elem.Codigo === this.sectorSelected) return true;
          return false;
        });
        if (sector[0]) label = `Sector: ${sector[0].Nombre}`;
      }
      if (this.palletNumber > 0) {
        if (label.length > 0) label += " - ";
        label = `${label} Número Pallet: ${this.palletNumber}`;
      }
      if (this.productSelected) {
        if (label.length > 0) label += " - ";
        label = `${label}Producto: ${this.getProductName()}`;
      }
      if (this.sizeSelected) {
        if (label.length > 0) label += " - ";
        label = `${label}Talle: ${this.getSizeName()}`;
      }
      if (this.presentationSelected) {
        if (label.length > 0) label += " - ";
        label = `${label}Presentación: ${this.getPresentationName()}`;
      }
      return label;
    },
  },
  watch: {
    sectorSelected(newValue) {
      this.$store.dispatch("setFilterSector", newValue);
    },
    palletNumber(newValue) {
      if (newValue && newValue > 0)
        this.$store.dispatch("setFilterPalletNumber", newValue);
      else this.$store.dispatch("setFilterPalletNumber", null);
    },
    async productSelected(newValue) {
    this.sizeSelected = null
    this.presentationSelected = null
    this.$store.dispatch("setFilterSize", null);
      this.$store.dispatch("setFilterPresentation", null);
      this.$store.dispatch("setFilterProduct", newValue);
      await Promise.all([this.getPresentations(), this.getSizes()]);
    },
    sizeSelected(newValue) {
      this.$store.dispatch("setFilterSize", newValue);
    },
    presentationSelected(newValue) {
      this.$store.dispatch("setFilterPresentation", newValue);
    },
  },
  beforeMount() {
    this.sectorSelected = this.getFilterSector;
    this.palletNumber = this.getFilterPalletNumber;
    this.productSelected = this.getFilterProduct;
    this.sizeSelected = this.getFilterSize;
    this.presentationSelected = this.getFilterPresentation;
  },
};
</script>

<style lang="scss" scoped>
.WarehouseFilters {
  margin: 2rem;

  &__Title {
    margin-left: 1rem;
    position: absolute;
    top: 0.5rem;
    display: inline-flex;
    align-items: baseline;

    h4 {
      display: inline;
    }
  }
}
</style>
