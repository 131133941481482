<template>
  <div class="VersionLabel">
    <label style="margin-bottom: 0">ValyStock versión: {{ version }} - Build: {{ build }} - GOTDev.uy</label>
  </div>
</template>

<script>
import {version} from '@/utils/version.js'

export default {
  computed: {
    version() {
      return version
    },
    build() {
      const buildInfo = require('@/utils/build.json')
      return buildInfo.buildNumber
    }
  }
}
</script>

<style lang="scss" scoped>
.VersionLabel {
  display: flex;
  justify-content: center;
  position: relative;
  bottom: 10px;
  width: 100%;
  padding-top: 20px;
  background-color: #ECECEC !important;
}
</style>
