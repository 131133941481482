<template>
	<div :class="show ? 'PalletDetails p-3' : 'PalletDetails--hidden'">
		<h4 class="PalletDetails__Title">Detalles</h4>
		<div class="PalletDetails__Contents">
			<b-button :disabled="disableInputs" variant="primary" class="float-right mb-3" @click="handleNew">Agregar</b-button>
			<b-table
				:fields="fields"
				:items="details"
				primary-key="Linea"
				bordered
				outlined
				small
				hover
				selectable
				@row-selected="rowSelected"
				select-mode="single"
				head-variant="dark"
				ref="dataTable"
			/>
		</div>
		<b-container fluid>
			<b-form v-if="showForm" class="PalletDetails__Form">
				<b-form-row>
					<b-col>
						<b-form-group
							id="Producto-InputGroup"
							label="Producto"
							label-for="Producto-Input"
						>
						</b-form-group>
					</b-col>
				</b-form-row>
				<b-form-row>
					<b-col>
						<b-button 
							@click="handleProductsModal"
							class="mb-3"
							:disabled="disableInputs"
							:variant="productSelected ? 'success' : 'info'"
						>
							{{ productButtonDescription }}
						</b-button>
					</b-col>
				</b-form-row>
				<b-modal hide-footer id="modal-details-products" title="Productos">
					<ProductsPrompt @productSelected="selectProduct" />
				</b-modal>
				<b-form-row v-if="productSelected">
					<b-col sm="4">
						<b-form-group
							id="Origin-InputGroup"
							label="Origen"
							label-for="Origin-Input"
						/>
					</b-col>
					<b-col sm="4">
						<b-form-group
							id="Talle-InputGroup"
							label="Talle"
							label-for="Talle-Input"
						>
						</b-form-group>
					</b-col>
					<b-col sm="4">
						<b-form-group
							id="Presentacion-InputGroup"
							label="Presentación"
							label-for="Presentacion-Input"
						/>
					</b-col>
				</b-form-row>
				<b-form-row v-if="productSelected">
					<b-col sm="4">
						<b-button 
							@click="showOrigin = true"
							class="mb-3"
							:disabled="disableInputs"
							:variant="originSelected ? 'success' : 'info'"
						>
							{{ originButtonDescription() }}
						</b-button>
					</b-col>
					<b-col sm="4">
						<b-button 
							@click="showSizes = true"
							class="mb-3"
							:disabled="disableInputs"
							:variant="sizeSelected ? 'success' : 'info'"
						>
							{{ sizeButtonDescription() }}
						</b-button>
					</b-col>
					<b-col sm="4">
						<b-button 
							@click="showPresentations = true"
							class="mb-3"
							:disabled="disableInputs"
							:variant="presentationSelected ? 'success' : 'info'"
						>
							{{ presentationButtonDescription() }}
						</b-button>
					</b-col>
				</b-form-row>
				<b-form-row v-if="showOrigin">
					<b-col>
							<b-list-group>
								<b-list-group-item
									button
									v-for="item in getOriginOptions()"
									:key="item.value"
									@click="selectOrigin(item)"
									:active="isOriginActive(item)"
								>
								{{item.text}}
								</b-list-group-item>
							</b-list-group>
					</b-col>
				</b-form-row>
				<b-form-row v-if="showSizes">
					<b-col>
							<b-list-group>
								<b-list-group-item
									button
									v-for="item in getSizeOptions()"
									:key="item.value"
									@click="selectSize(item)"
									:active="isSizeActive(item)"
								>
								{{item.text}}
								</b-list-group-item>
							</b-list-group>
					</b-col>
				</b-form-row>
				<b-form-row v-if="showPresentations">
					<b-col>
							<b-list-group>
								<b-list-group-item
									button
									v-for="item in getPresentationOptions()"
									:key="item.value"
									@click="selectPresentation(item)"
									:active="isPresentationActive(item)"
								>
								{{item.text}}
								</b-list-group-item>
							</b-list-group>
					</b-col>
				</b-form-row>
				<b-form-row >
					<b-col sm="6">
						<b-form-group
							id="Cajas-InputGroup"
							label="Cajas"
							label-for="Cajas-Input"
						>
							<b-input :disabled="disableInputs" id="Cajas-Input" v-model="packages" />
						</b-form-group>
					</b-col>
				</b-form-row>
				<b-form-row v-if="presentationSelected">
					<b-col>
						<b-form-group
							id="Peso-InputGroup"
							label="Peso"
							label-for="Peso-Input"
						>
							<b-form-row>
								<b-col sm="3">
									<span class="Pallet__Label" :class="customWeight?'PalletDetails__Label--cancelled':''">
										{{`${this.getPresentationPalletWeight(this.productSelected.Codigo, this.presentationSelected.value).PesoCaja * this.packages} Kg.`}}
									</span>
								</b-col>
								<b-col>
									<b-form-checkbox :disabled="disableInputs" id="Peso-Input" v-model="customWeight" size="lg">Indicar otro</b-form-checkbox>
								</b-col>
							</b-form-row>
						</b-form-group>
					</b-col>
				</b-form-row>
				<b-form-row v-if="presentationSelected && customWeight">
					<b-col>
						<b-form-group
							id="PesoCustom-InputGroup"
						>
							<b-col class="ml-n3 PalletDetails__Input" sm="6">
								<b-input
									id="PesoCustom-Input"
									type="text"
									:disabled="disableInputs"
									v-model="customWeightValue"
								/>
								<span class="ml-2">Kg.</span>
							</b-col>
						</b-form-group>
					</b-col>
				</b-form-row>
				<b-form-row>
					<b-col sm="4">
						<b-form-group
							id="FechaProduccion-InputGroup"
							label="Fecha de producción"
							label-for="FechaProduccion-Input"
						/>
					</b-col>
					<b-col sm="4">
						<b-form-group
							id="FechaIngreso-InputGroup"
							label="Fecha de ingreso"
							label-for="FechaIngreso-Input"
						/>
					</b-col>
					<b-col sm="4">
						<b-form-group
							id="FechaVencimiento-InputGroup"
							label="Fecha de vencimiento"
							label-for="FechaVencimiento-Input"
						/>
					</b-col>
				</b-form-row>
				<b-form-row>
					<b-col sm="4">
						<datetime
							input-class="PalletDetails__Date"
							input-id="FechaProduccion-Input"
							v-model="productionDate"
							type="date"
							:disabled="disableInputs"
							:phrases="{ok: 'Aceptar', cancel: 'Cancelar'}"
							value-zone="America/Montevideo"
							zone="America/Montevideo"
						/>
						<span>{{getProductionNumber}}</span>
					</b-col>
					<b-col sm="4">
						<datetime
							input-class="PalletDetails__Date"
							input-id="FechaIngreso-Input"
							v-model="receptionDate"
							type="datetime"
							:disabled="disableInputs"
							:phrases="{ok: 'Aceptar', cancel: 'Cancelar'}"
							:flow="['date', 'time']"
							value-zone="America/Montevideo"
							zone="America/Montevideo"
						/>
					</b-col>
					<b-col sm="4">
						<datetime
							input-class="PalletDetails__Date"
							input-id="FechaVencimiento-Input"
							v-model="expiryDate"
							type="date"
							:disabled="disableInputs"
							:phrases="{ok: 'Aceptar', cancel: 'Cancelar'}"
							:flow="['year', 'date']"
							value-zone="America/Montevideo"
							zone="America/Montevideo"
						/>
					</b-col>
				</b-form-row>
				<b-alert class="mt-3" variant="danger" v-model="showError" :dismissible="true">
					{{ errorMessage }}
				</b-alert>
				<b-form-row class="mt-3 justify-content-end">
					<b-col class="text-right" >
						<b-button variant="outline-danger" @click="handleCancel">Cancelar</b-button>
						<b-button v-if="operation === 'create'" :disabled="disableInputs" variant="success" class="ml-3" @click="handleSave">Guardar</b-button>
						<b-button v-if="operation === 'update||delete'" :disabled="disableInputs" variant="secondary" class="ml-3" @click="handleDelete">Borrar</b-button>
						<b-button v-if="operation === 'update||delete'" :disabled="disableInputs" variant="success" class="ml-3" @click="handleSave">Modificar</b-button>
					</b-col>
				</b-form-row>
			</b-form>
		</b-container>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import { Datetime } from 'vue-datetime'
import { validatePalletData } from '@/utils/palletOperations.js'
import productionMixin from '@/mixins/productionNumberMixin.js'
import ProductsPrompt from '@/components/Pages/ProductsPrompt.vue'

export default {
	props: {
		show: Boolean,
		activeProducts: Array,
		defaultLinesData: Array,
		disableInputs: Boolean
	},
	data() {
		return {
			fields: [
				{
					key: 'Linea',
					class: 'PalletDetails__Column--hidden'
				},
				{
					key: 'Producto',
					sortable: true
				},
				{
					key: 'Origen'
				},
				{
					key: 'Talle',
					sortable: true
				},
				{
					key: 'Presentacion',
					label: 'Presentación',
					sortable: true
				},
				{
					key: 'Ingreso',
					sortable: true
				},
				{
					key: 'Produccion',
					label: 'Producción',
					sortable: true
				},
				{
					key: 'Vencimiento',
					sortable: true
				},
				{
					key: 'Cajas',
					sortable: false
				},
				{
					key: 'Peso',
					sortable: false
				}
			],
			lastLine: 0,
			currentLine: 0,
			details: [],
			detailsData: [],
			operation: '',
			showForm: false,
			showProducts: false,
			showOrigin: false,
			showSizes: false,
			showPresentations: false,
			showError: false,
			productSelected: null,
			originSelected: null,
			sizeSelected: null,
			presentationSelected: null,
			customWeightValue: 0,
			customWeight: false,
			receptionDate: this.$moment.tz(new Date(), "America/Montevideo").format('YYYY-MM-DDTHH:mm'),
			expiryDate: '',
			packages: 0,
			errorMessage: ''
		}
	},
	components: {
		Datetime,
		ProductsPrompt
	},
	mixins: [productionMixin],
	methods: {
		handleProductsModal() {
			this.$bvModal.show('modal-details-products')
		},
		selectProduct(product) {
			this.productSelected = product
			this.$bvModal.hide('modal-details-products')
			const sizes = this.getSizesOfProduct(product.Codigo)
			const presentations = this.getPresentationsOfProduct(product.Codigo)
			this.sizeSelected = null
			this.presentationSelected = null
			if (sizes.length === 1) {
				this.sizeSelected = {
					value: sizes[0].Codigo,
					text: sizes[0].Nombre
				}
			} else {
				this.sizeSelected = null
			}
			if (presentations.length === 1) {
				this.presentationSelected = {
					value: presentations[0].Codigo,
					text: presentations[0].Descripcion
				}
			} else {
				this.presentationSelected = null
			}
		},
		getPresentationOptions() {
			let opt = []
			if (this.productSelected) {
				const presentations = this.getPresentationsOfProduct(this.productSelected.Codigo)
				for (let i = 0; i < presentations.length; i++) {
					opt.push({
						value: presentations[i].Codigo,
						text: presentations[i].Descripcion
					})
				}
			}
			return opt
		},
		presentationButtonDescription() {
			if (this.productSelected) {
				if (this.presentationSelected) {
					return this.presentationSelected.text
				} else {
					return 'Seleccionar'
				}
			} else {
				return '' 
			}
		},
		getOriginOptions() {
			let opt = []			
			const origins = [ {
				Codigo: 'PRODUCCION',
				Descripcion: 'Producción'
			}, {
				Codigo: 'IMPORTACION',
				Descripcion: 'Importación'
			}] 
			for (let i = 0; i < origins.length; i++) {
				opt.push({
					value: origins[i].Codigo,
					text: origins[i].Descripcion
				})
			}
			return opt
		},
		originButtonDescription() {
			if (this.originSelected) {
				return this.originSelected.text
			} else {
				return 'Seleccionar'
			}
		},
		getSizeOptions() {
			let opt = []
			if (this.productSelected) {
				const sizes = this.getSizesOfProduct(this.productSelected.Codigo)
				for (let i = 0; i < sizes.length; i++) {
					opt.push({
						value: sizes[i].Codigo,
						text: sizes[i].Nombre
					})
				}
			}
			return opt
		},
		sizeButtonDescription() {
			if (this.productSelected) {
				if (this.sizeSelected) {
					return this.sizeSelected.text
				} else
				return 'Seleccionar'
			} else {
				return ''
			}
		},
		selectOrigin(origin) {
			this.originSelected = origin
			this.showOrigin = false
		},
		isOriginActive(origin) {
			if (this.originSelected)
				return origin.value === this.originSelected.value
			else
				return false
		},
		selectSize(size) {
			this.sizeSelected = size
			this.showSizes = false
		},
		isSizeActive(size) {
			if (this.sizeSelected) 
				return size.value === this.sizeSelected.value
			else
				return false
		},
		selectPresentation(presentation) {
			this.presentationSelected = presentation
			this.showPresentations = false
		},
		isPresentationActive(presentation) {
			if (this.presentationSelected)
				return presentation.value === this.presentationSelected.value
			else
				return false
		},
		rowSelected(rowData) {
			if (rowData.length > 0) {
				const data = rowData[0]
				this.currentLine = data.Linea
				const lineElement =	this.detailsData.filter((elem) => {
					return elem.Linea === data.Linea
				})[0]
				this.showForm = true
				this.operation = 'update||delete'
				this.showProducts = false
				this.showOrigin = false
				this.showSizes = false
				this.showPresentations = false
				this.showError = false
				this.productSelected = {
					Codigo: lineElement.Producto.value,
					Nombre: lineElement.Producto.text
				}
				this.originSelected = lineElement.Origen
				this.sizeSelected = lineElement.Talle
				this.presentationSelected = lineElement.Presentacion
				this.customWeightValue = lineElement.PesoCustom ? lineElement.Peso : 0
				this.customWeight = lineElement.PesoCustom
				this.productionDate = this.$moment.tz(lineElement.Produccion, "America/Montevideo").format('YYYY-MM-DD')
				this.receptionDate = this.$moment.tz(lineElement.Ingreso, "America/Montevideo").format('YYYY-MM-DDTHH:mm')
				this.expiryDate = (lineElement.Vencimiento && lineElement.Vencimiento.length > 0) 
					? this.$moment.tz(lineElement.Vencimiento, "America/Montevideo").format('YYYY-MM-DD') : ''
				this.packages = lineElement.Cajas
				this.errorMessage = ''			
			}
		},
		handleNew() {
			this.operation = 'create'
			this.showForm = true
		},
		handleCancel() {
			this.operation = ''
			this.showForm = false
			this.clearFields()
		},
		async handleDelete() {
			const confirmationResponse = await this.$bvModal.msgBoxConfirm('¿Desea eliminar el registro?', {
				title: 'Confirmación',
				okTitle: 'Confirmar',
				cancelTitle: 'Cancelar'
			})
			if (confirmationResponse === true) {
				this.details = this.details.filter((element) => {
					return element.Linea !== this.currentLine
				})
				this.detailsData = this.detailsData.filter((element) => {
					return element.Linea !== this.currentLine
				})
			}
			this.$store.dispatch("setCurrentPalletDetails", this.detailsData)
			this.clearFields()
		},
		handleSave() {
			this.showError = false
			this.errorMessage = ''
			const validatePallet = validatePalletData(
				0,
				false,
				0,
				this.originSelected,
				this.sizeSelected,
				this.presentationSelected,
				this.customWeight,
				this.customWeightValue,
				this.productionDate,
				this.receptionDate,
				this.expiryDate,
				this.packages,
				this.$moment,
				true
			)
			if (!validatePallet.success) {
				this.showError = true
				this.errorMessage = validatePallet.message
				return
			}
			switch (this.operation) {

				case 'create':
					this.lastLine ++ 
					this.details.push({
						Codigo: 0,
						Linea: this.lastLine,
						Producto: this.productButtonDescription,
						Origen: this.originButtonDescription(),
						Talle: this.sizeButtonDescription(),
						Presentacion: this.presentationButtonDescription(),
						Ingreso: this.$moment.tz(this.receptionDate, "America/Montevideo").format('DD/MM/YYYY-HH:mm'), 
						Produccion: this.$moment.tz(this.productionDate, "America/Montevideo").format('DD/MM/YYYY'),
						Vencimiento: this.expiryDate.length > 0 ? this.$moment.tz(this.expiryDate, "America/Montevideo").format('DD/MM/YYYY') : '',
						Cajas:	this.packages,
						Peso: this.customWeight ? this.customWeightValue : 
							this.getPresentationPalletWeight(this.productSelected.Codigo, this.presentationSelected.value).PesoCaja * this.packages
					})
					this.detailsData.push({
						Codigo: 0,
						Linea: this.lastLine,
						Producto: {
							value: this.productSelected.Codigo,
							text: this.productSelected.Nombre
						},
						Origen: this.originSelected,
						Talle: this.sizeSelected,
						Presentacion: this.presentationSelected,
						Ingreso: this.receptionDate,
						Produccion: this.productionDate,
						Vencimiento: this.expiryDate,
						Cajas: this.packages,
						PesoCustom: this.customWeight,
						Peso:	this.customWeight ? this.customWeightValue : 
							this.getPresentationPalletWeight(this.productSelected.Codigo, this.presentationSelected.value).PesoCaja * this.packages
					})	
					break
				case 'update||delete':
					for (let i = 0; i < this.details.length; i ++) {
						if (this.details[i].Linea === this.currentLine) {
							this.details[i].Producto = this.productButtonDescription
							this.details[i].Origen = this.originButtonDescription()
							this.details[i].Talle = this.sizeButtonDescription()
							this.details[i].Presentacion = this.presentationButtonDescription()
							this.details[i].Ingreso = this.$moment.tz(this.receptionDate, "America/Montevideo").format('DD/MM/YYYY-HH:mm') 
							this.details[i].Produccion = this.$moment.tz(this.productionDate, "America/Montevideo").format('DD/MM/YYYY')
							this.details[i].Vencimiento = this.expiryDate.length > 0 ? this.$moment.tz(this.expiryDate, "America/Montevideo").format('DD/MM/YYYY') : ''
							this.details[i].Cajas =	this.packages
							this.details[i].Peso = this.customWeight ? this.customWeightValue : 
								this.getPresentationPalletWeight(this.productSelected.Codigo, this.presentationSelected.value).PesoCaja * this.packages
							break
						}
					}
					for (let i = 0; i < this.detailsData.length; i ++) {
						if (this.detailsData[i].Linea === this.currentLine) {
							this.detailsData[i].Producto = {
								text: this.productSelected.Nombre,
								value: this.productSelected.Codigo
							}
							this.detailsData[i].Origen = this.originSelected
							this.detailsData[i].Talle = this.sizeSelected
							this.detailsData[i].Presentacion = this.presentationSelected
							this.detailsData[i].Ingreso = this.receptionDate
							this.detailsData[i].Produccion = this.productionDate
							this.detailsData[i].Vencimiento = this.expiryDate
							this.detailsData[i].Cajas = this.packages
							this.detailsData[i].PesoCustom = this.customWeight
							this.detailsData[i].Peso =	this.customWeight ? this.customWeightValue : 
								this.getPresentationPalletWeight(this.productSelected.Codigo, this.presentationSelected.value).PesoCaja * this.packages
							break
						}
					}
					break
			}
			this.$store.dispatch("setCurrentPalletDetails", [...this.detailsData])
			this.clearFields()
		},
		clearFields() {			
			this.operation = ''
			this.showForm = false
			this.showProducts = false
			this.showOrigin = false
			this.showSizes = false
			this.showPresentations = false
			this.showError = false
			this.productSelected = null
			this.originSelected = null
			this.presentationSelected = null
			this.sizeSelected = null
			this.customWeightValue = 0
			this.customWeight = false
			this.productionDate = this.$moment.tz(new Date(), "America/Montevideo").format('YYYY-MM-DD')
			this.receptionDate = this.$moment.tz(new Date(), "America/Montevideo").format('YYYY-MM-DDTHH:mm')
			this.expiryDate = ''
			this.packages = 0
			this.errorMessage = ''
			this.currentLine = 0
			this.$refs.dataTable.clearSelected()
		}
	},
	computed: {
		...mapGetters([
			'getSizesOfProduct',
			'getPresentationsOfProduct',
			'getPresentationPalletWeight'	
		]),
		productButtonDescription: function() {
			if (this.productSelected)
				return this.productSelected.Nombre
			else
				return 'Seleccionar'
		}
	},
	mounted() {
		if (this.defaultLinesData.length > 0) {
			this.detailsData = this.defaultLinesData
			//this.lastLine = this.defaultLinesData[this.defaultLinesData.length - 1].Codigo
			this.lastLine = 0
			for (let i = 0; i < this.detailsData.length; i ++) {
				this.lastLine ++
				this.details.push({
					Linea: this.lastLine,
					Codigo: this.detailsData[i].Codigo,
					Producto: this.detailsData[i].Producto.text,
					Origen: this.detailsData[i].Origen.text,
					Talle: this.detailsData[i].Talle.text,
					Presentacion: this.detailsData[i].Presentacion.text,
					Ingreso: this.$moment.tz(this.detailsData[i].Ingreso, "America/Montevideo").format('DD/MM/YYYY-HH:mm'),
					Produccion: this.$moment.tz(this.detailsData[i].Produccion, "America/Montevideo").format('DD/MM/YYYY'),
					Vencimiento: (this.detailsData[i].Vencimiento && this.detailsData[i].Vencimiento.length > 0) 
						? this.$moment.tz(this.detailsData[i].Vencimiento, "America/Montevideo").format('DD/MM/YYYY') : '',
					Cajas: this.detailsData[i].Cajas,
					Peso: this.detailsData[i].Peso
				})
			}
			this.$store.dispatch("setCurrentPalletDetails", this.detailsData)
		}
	}
}
</script>

<style lang="scss">
	.PalletDetails {
		transition: width .3s;
		width: 930px;
		background-color: white;
		position: absolute;
		top: -10px;
		left: 485px;
		overflow: hidden;
		border-style: solid;
		border-width: 1px;
		box-shadow: 5px 5px 5px 0px rgba(0,0,0,0.35);

		&--hidden {
			width: 0;
			height: 0;
			overflow: hidden;
		}

		&__Column--hidden {
			display: none;
		}

		&__Contents {
			box-sizing: border-box;
			margin-top: 1rem;
			width: 100%;
			display: block;
		}

		&__Form {
			border-style: solid;
			border-width: 1px;
			border-radius: 5px;
			padding: 1.5rem;
			margin: 1.5rem;
		}

		&__Label {
			font-size: 1.2rem;

			&--cancelled {
				text-decoration: line-through;
			}
		}
		
		&__Input {
			display: flex;
			align-items: baseline;
			font-size: 1.2rem;
		}

		&__Date {
			width: 220px !important;
		}
	}
</style>