<template>
	<div class="CloseBar bg-dark">
		<span class="pl-3" v-if="title">{{title}}</span>
		<img v-b-tooltip.hover title="Cerrar" 
			src="@/assets/close-icon.jpg" alt="botón cerrar" 
			class="CloseBar__Image"
			@click="$emit('close')"
		>
	</div>	
</template>

<script>
export default {
	props: {
		title: String
	}
}
</script>

<style lang="scss" scoped>
	.CloseBar {
		display: flex;
		justify-content: flex-end;
		align-items: center;
		flex: 1;
		margin: -1rem;

		& span {
			color: white;
			margin-right: auto;
			font-size: 1.3rem;
		}

		&__Image {
			width: 37px;
			padding: 5px;
			cursor: pointer;
		}
	}
</style>
