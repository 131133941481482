<template>
	<div>
		<b-container>
			<b-row>
				<b-col cols="12">
					<div class="page-header">
						<h1>Cambio de Contraseña</h1>
					</div>
				</b-col>
			</b-row>
			<b-row v-if="error">
				<b-col cols="12">
					<div class="alert alert-danger">{{error}}</div>
				</b-col>
			</b-row>
			<b-row v-if="success">
				<b-col cols="12">
					<div class="alert alert-success">{{success}}</div>
				</b-col>
			</b-row>
			<b-form>
				<b-row>
					<b-col cols="12">
						<b-form-group
							id="input-group-old-password"
							label="Contraseña actual:"
							label-for="input-old-password"
						>
							<b-form-input
								id="input-old-password"
								v-model="userOldPassword"
								type="password"
								required
							/>
						</b-form-group>
					</b-col>
				</b-row>
				<b-row>
					<b-col cols="12">
						<b-form-group
							id="input-group-new-password"
							label="Nueva Contraseña:"
							label-for="input-new-password"
						>
							<b-form-input
								id="input-new-password"
								v-model="userNewPassword"
								type="password"
								required
							/>
						</b-form-group>
					</b-col>
				</b-row>
				<b-row>
					<b-col cols="12">
						<b-form-group
							id="input-group-new-password-conf"
							label="Confirmación nueva contraseña:"
							label-for="input-new-password-conf"
						>
							<b-form-input
								id="input-new-password-conf"
								v-model="userNewPasswordConf"
								type="password"
								required
							/>
						</b-form-group>
					</b-col>
				</b-row>
				<b-row align-h="end">
					<b-col cols="4">
						<p class="float-right">
							<b-button @click="submit" type="submit" variant="primary">Confirmar</b-button>
							<b-button @click="cancel" class="ml-2" type="reset" variant="outline-danger">Cancelar</b-button>
						</p>
					</b-col>
				</b-row>
			</b-form>
		</b-container>
	</div>
</template>

<script>
import { changePassword } from '@/firebase/utils';
import 'firebase/auth'

export default {
	data() {
		return {
			userOldPassword: '',
			userNewPassword: '',
			userNewPasswordConf: '',
			error: '',
			success: ''
		}
	},
	methods: {
		async submit(e) {
			this.error = ''
			this.success = ''
			e.preventDefault()
            if (this.userOldPassword && this.userNewPassword && this.userNewPasswordConf){ //ejecuta solo si los 3 campos tienen datos, si falta alguno no ejecuta
                if (this.userNewPassword == this.userNewPasswordConf) {
					let result = await changePassword(this.userOldPassword, this.userNewPassword)
					
					this.userOldPassword = ''
					this.userNewPassword = ''
					this.userNewPasswordConf = ''

					if (result == 'OK') {
						this.success = 'Contraseña modificada exitosamente'
					} else {
						this.error = result
					}
                } else {
					this.error = 'Las contraseñas no coinciden'
				}
            } else {
				this.error = 'Debe completar los 3 campos de contraseñas'
			}
        },
        checkSubmit(e) {
			if (e.keyCode === 13)
				this.submit()
		},
		cancel() {
			this.$router.go(-1)
		}
	},
	computed: {
		displayName: {
			get() {
				return this.$store.getters.userDisplayName
			},
			set(value) {
				this.form.name = value 
			}
		}
	}
}
</script>
