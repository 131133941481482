<template>
	<div class="Location bg-info p-3">
		<b-row class="mb-2">
			<b-col sm="2">
				<span>Cámara: </span>
			</b-col>
			<b-col sm="3">
				<span class="Location__Data">{{camera}}</span>
			</b-col>
			<b-col sm="2">
				<span> Sector: </span>
			</b-col>
			<b-col sm="5">
				<span class="Location__Data">{{sector}}</span>
			</b-col>
		</b-row>
		<b-row class="mb-2">
			<b-col sm="2">
				<span>Columna:</span>
			</b-col>
			<b-col sm="3">
				<span class="Location__Data">{{column}}</span>
			</b-col>
			<b-col sm="2">
				<span>Piso:</span>
			</b-col>
			<b-col sm="5">
				<span class="Location__Data">{{floor}}</span>
			</b-col>
		</b-row>
		<b-row>
			<b-col sm="2">
				<span>Posición:</span>
			</b-col>
			<b-col sm="3">
				<span class="Location__Data">{{getPosLetter(position)}}</span>
			</b-col>
		</b-row>
	</div>
</template>

<script>
import { getPositionLetter } from '@/utils/newPalletPosition.js'

export default {
	props: [
		'camera',
		'sector',
		'column',
		'floor',
		'position'
	],
	methods: {
		getPosLetter(position) {
			return getPositionLetter(position)
		}
	}
}
</script>

<style lang="scss" scoped>
	.Location {
		border-radius: 2px;
		color: #FFF;
		text-align: left;
		& span {
			font-size: 1rem;
			font-weight: 700;
		}

		&__Data {
			font-size: 1.3rem;
			font-weight: 200 !important;
			margin-left: 1rem;
		}
	}
</style>