<template>
  <div class="Popover">
    <header class="Popover__Header">
      <span class="Popover__Header__Label">
        Pallet:
        <span>{{ pallet.Numero }}</span>
      </span>
    </header>
    <main>
      <b-row>
        <span class="Popover__Label">Producto:</span>
      </b-row>
      <b-row class="mb-2">
        <span class="Popover__Data">{{ product }}</span>
      </b-row>
      <b-row>
        <b-col>
          <span class="Popover__Label">Peso:</span>
        </b-col>
        <b-col>
          <span class="Popover__Label">Talle:</span>
        </b-col>
      </b-row>
      <b-row class="mb-2">
        <b-col>
          <span class="Popover__Data">{{ weight }} Kg.</span>
        </b-col>
        <b-col>
          <span class="Popover__Data">{{ sizeDescription }}</span>
        </b-col>
      </b-row>
      <b-row v-if="pallet.TieneDetalle === false">
        <span class="Popover__Label">Código de producción:</span>
      </b-row>
      <b-row class="mb-2" v-if="pallet.TieneDetalle === false">
        <span class="Popover__Data">{{ getProductionNumber(productionDate) }}</span>
      </b-row>
      <b-row v-if="pallet.TieneDetalle === false">
        <span class="Popover__Label">Fecha de producción:</span>
      </b-row>
      <b-row class="mb-2" v-if="pallet.TieneDetalle === false">
        <span class="Popover__Data">{{ productionDate }}</span>
      </b-row>
      <b-row v-if="pallet.TieneDetalle === false">
        <span class="Popover__Label">Fecha ingreso:</span>
      </b-row>
      <b-row class="mb-2" v-if="pallet.TieneDetalle === false">
        <span class="Popover__Data">{{ receptionDate }}</span>
      </b-row>
      <b-row class="mb-2" align-h="center" v-if="pallet.TieneDetalle === true">
        <span class="Popover__Label">Pallet compuesto</span>
      </b-row>

      <div class="Popover__Actions mt-3">
        <b-button variant="outline-primary mt-2" size="lg" @click="onMove">Mover a Piso</b-button>
        <b-button variant="outline-primary mt-2" size="lg" @click="onChangeWarehouse">Mover de cámara</b-button>
        <b-button variant="outline-primary mt-2" size="lg" @click="onRemove">Dar salida</b-button>
        <b-button variant="outline-success mt-2" size="lg" @click="onEdit">Editar</b-button>
        <b-button variant="outline-success mt-2" size="lg" @click="onDuplicate">Duplicar</b-button>
      </div>
    </main>
    <b-modal id="modal-move" modal-class="modal-move"
             header-bg-variant="light"
             ok-title="Aceptar"
             cancel-title="Cancelar"
             @ok="handleMove"
             centered title="Mover a piso">
      <p class="my-4">¿Quieres mover el pallet {{ pallet.Numero }}?</p>
    </b-modal>
    <b-modal centered id="CameraSelector" aria-label="Selección de cámara">
      <template v-slot:modal-header="{ close }">
        <CloseBar title="Cámaras" @close="close()"/>
      </template>
      <b-container fluid>
        <b-row>
          <b-col cols="12">
            <CameraSelector :default-behaviour="false" @selected-camera="changePalletCamera" @close="hideCameraSelector"/>
          </b-col>
        </b-row>
      </b-container>
      <template v-slot:modal-footer>
        <div></div>
      </template>
    </b-modal>
    <b-modal id="modal-duplicate" modal-class="modal-move"
             header-bg-variant="light"
             centered
             :title="`Duplicar pallet - ${pallet.Numero}`">
      <DuplicationForm @cancel="handleDuplicateCancel" @save="handleDuplicateSave"/>
      <template v-slot:modal-footer>
        <div style="display: none;"></div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import {
  duplicatePallet,
  getPalletByLocation,
  getPalletDetails,
  movePallet
} from '@/api';
//import {doPushFoward} from '@/utils/palletOperations.js'
import {mapGetters} from 'vuex'
import DuplicationForm from '@/components/DuplicationForm.vue'
import CameraSelector from '@/components/Pages/CameraSelector';
import CloseBar from '@/components/CloseBar';


export default {
  props: {
    locationCode: Number
  },
  components: {
    DuplicationForm,
    CameraSelector,
    CloseBar
  },
  data() {
    return {
      rawProductDate: '',
      pallet: {
        Codigo: '',
        Numero: ''
      },
      palletDetails: [],
      product: '',
      weight: 0,
      sizeDescription: '',
      productionDate: '',
      receptionDate: ''
    }
  },
  computed: {
    ...mapGetters([
      'getCurrentCamera',
      'getActiveProducts',
      'getPalletLocation',
      'getUbicaciones'
    ])
  },
  methods: {
    async getPallet() {
      this.pallet = await getPalletByLocation(this.locationCode)
      if (this.pallet) {
        this.palletDetails = await getPalletDetails(this.pallet.Codigo)
        if (this.palletDetails.length > 0) {
          const productCode = this.palletDetails[0].Talle.ProductoCodigo
          this.getActiveProducts.forEach((element) => {
            if (element.Codigo === productCode) {
              this.product = element.Nombre
            }
          })
        }
        this.weight = 0
        for (let i = 0; i < this.palletDetails.length; i++) {
          this.weight = this.weight + parseInt(this.palletDetails[i].Peso)
          this.sizeDescription = this.palletDetails[i].Talle.Nombre
        }
        if (this.palletDetails.length === 1) {
          this.rawProductDate = this.palletDetails[0].FechaProduccion
          this.productionDate = this.$moment.tz(this.palletDetails[0].FechaProduccion, "America/Montevideo").format('DD/MM/YYYY')
          this.receptionDate = this.$moment.tz(this.palletDetails[0].FechaIngreso, "America/Montevideo").format('DD/MM/YYYY HH:mm')
        }
      }
    },
    onRemove() {
      this.$store.dispatch('setCurrentOperation', 'remove')
      this.pallet.details = this.palletDetails
      this.$store.dispatch('setCurrentPallet', this.pallet)
      const currentPopover = this.$store.getters.getCurrentPopover
      if (currentPopover != null) {
        this.$store.dispatch('setClosePopover', currentPopover)
      }
      this.$store.dispatch('openPallet')
      window.scrollTo(0, 0)
    },
    onMove() {
      this.$bvModal.show('modal-move')
    },
    onChangeWarehouse() {
      this.$bvModal.show('CameraSelector')
    },
    async changePalletCamera(codigo) {
    
      movePallet(this.pallet.Codigo,0,codigo)

      const CameraCode = this.getCurrentCamera.Codigo
      await this.$store.dispatch('setPalletsInTemporaryLocation', CameraCode)
      await this.$store.dispatch('setCurrentCamera', CameraCode)

      await this.$store.dispatch('setPalletsInTemporaryLocation', codigo)
      await this.$store.dispatch('setCurrentCamera', codigo)
    },
    hideCameraSelector() {
      this.$bvModal.hide('CameraSelector')
    },
    onDuplicate() {
      this.$bvModal.show('modal-duplicate')
    },
    getProductionNumber: function () {
      if (this.rawProductDate && this.rawProductDate.length > 0) {
        const year = this.$moment.tz(this.rawProductDate, "America/Montevideo").year()
        const dayOfYear = this.$moment.tz(this.rawProductDate, "America/Montevideo").dayOfYear()
        return `${year}-${dayOfYear}`
      } else {
        return ''
      }
    },
    onEdit() {
      let currentPopover = this.$store.getters.getCurrentPopover
      if (currentPopover != null) {
        this.$store.dispatch('setClosePopover', currentPopover)
      }
      this.$store.dispatch('setCurrentOperation', 'edit')
      this.pallet.details = this.palletDetails
      this.$store.dispatch('setCurrentPallet', this.pallet)
      this.$store.dispatch('openPallet')
      window.scrollTo(0, 0)
    },
    async handleMove() {
      
      let currentPopover = this.$store.getters.getCurrentPopover
      movePallet(this.pallet.Codigo,0,0)

      this.$emit('close')

      if (currentPopover != null) {
        await this.$store.dispatch('setClosePopover', currentPopover)
      }
      const CameraCode = this.getCurrentCamera.Codigo
      await this.$store.dispatch('setPalletsInTemporaryLocation', CameraCode)
      await this.$store.dispatch('setCurrentCamera', CameraCode)
    },
    handleDuplicateCancel() {
      this.$bvModal.hide('modal-duplicate')
    },
    async handleDuplicateSave(newPalletNumber) {
      await duplicatePallet(this.pallet.Codigo, newPalletNumber, this.getCurrentCamera.Codigo)
      this.$bvModal.hide('modal-duplicate')
      const currentPopover = this.$store.getters.getCurrentPopover
      if (currentPopover != null) {
        await this.$store.dispatch('setClosePopover', currentPopover)
      }
    }
  },
  created() {
    this.getPallet()
  }
}
</script>

<style lang="scss" scoped>
.Popover {
  &__Header {
    background-color: transparent;
    width: 272px;
    margin: -.45rem -.7rem;
    padding: 0 1rem;
    height: 33px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.2rem;
    border-bottom-style: solid;
    border-bottom-color: #AAA;
    border-bottom-width: 1px;

    &__Label {
      font-weight: 700;

      & span {
        font-weight: 400;
      }
    }
  }

  &__Data {
    font-size: 1.1rem;
    display: block;
    padding-left: 10px;
  }

  &__Label {
    font-size: 1.1rem;
    margin-right: 10px;
    font-weight: 700;
  }

  &__Actions {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
  }
}


#modal-move___BV_modal_outer_ {
  z-index: 1060 !important;
}

#modal-duplicate___BV_modal_outer_ {
  z-index: 1060 !important;
}

main {
  padding: 20px 10px;
}

#CameraSelector___BV_modal_outer_ {
  z-index: 1060 !important;
}
</style>
