export const getPositionLetter = (position) => {
    switch(position) {
        case 1:
            return 'A'
        case 2:
            return 'B'
        case 3:
            return 'C'
    }
}
