<template>
	<div>
		<b-container>
			<b-row>
				<b-col cols="12">
					<div class="page-header">
						<h1>Perfil</h1>
					</div>
				</b-col>
			</b-row>
			<b-row v-if="error">
				<b-col cols="12">
					<div class="alert alert-danger">{{error}}</div>
				</b-col>
			</b-row>
					<b-form @submit="onSubmit" @reset="onReset">
						<b-row>
							<b-col cols="12">
								<b-form-group
									id="input-group-email"
									label="Email:"
									label-for="input-email"
									description="Tu dirección de email no puede ser modificada por el momento"
								>
									<b-form-input
										id="input-email"
										v-model="this.$store.getters.user.profileData.email"
										type="email"
										required
										disabled
									/>
								</b-form-group>
							</b-col>
						</b-row>
						<b-row>
							<b-col cols="12">
								<b-form-group
									id="input-group-profilePicture"
									label="Foto de perfil:"
									label-for="input-profilePicture"
									description="Tu foto de perfil no puede ser modificada por el momento"
								>
									<b-form-file
										id="input-profilePicture"
										accept="image/jpeg, image/png, image/gif"
										v-model="form.profilePicture"
										placeholder="Selecciona un archivo o arrástralo aquí"
										drop-placeholder="Suelta el archivo aquí"
										browse-text="Seleccionar"
										disabled
									/>
								</b-form-group>
							</b-col>
						</b-row>
						<b-row>
							<b-col cols="12">
								<b-form-group
									id="input-group-displayName"
									label="Nombre:"
									label-for="input-displayName"
								>
									<b-form-input id="input-displayName" v-model="displayName" placeholder="Ingresa tu nombre"></b-form-input>
								</b-form-group>
							</b-col>
						</b-row>
						<b-row>
							<b-col cols="12">
								<b-form-group
									id="input-group-password"
									label="Contraseña:"
									label-for="input-password"
								>
									<router-link
										to="/CambioPasword"
										class="pull-right"
										activeClass="pull-right" 
									>
										Cambiar mi contraseña
									</router-link>
								</b-form-group>	
							</b-col>
						</b-row>
						<b-row align-h="end">
							<b-col cols="4">
								<p class="float-right">
									<b-button type="submit" variant="primary">Confirmar</b-button>
									<b-button class="ml-2" type="reset" variant="outline-danger">Cancelar</b-button>
								</p>
							</b-col>
						</b-row>
					</b-form>
		</b-container>
	</div>
</template>

<script>
import firebase from 'firebase/app';
import 'firebase/auth'

export default {
	data() {
		return {
			form: {
				profilePicture: [],
				name: '',
				password: 'default password'
			},
			error: ''
		}
	},
	methods: {
		async onSubmit(evt) {
			evt.preventDefault()
			// TODO: handle picture uploads
			// TODO: handle change email with reauthentication
			let currentUser = firebase.auth().currentUser
			try {
				await currentUser.updateProfile({
					displayName: this.form.name
				})
				this.$store.dispatch("setUserLogged", currentUser)
				this.$router.push({
					path: '/'
				})
			} catch(err) {
				this.error = err
			}
		},
		onReset() {
			this.$router.go(-1)
		}
	},
	computed: {
		displayName: {
			get() {
				return this.$store.getters.userDisplayName
			},
			set(value) {
				this.form.name = value 
			}
		}
	}
}
</script>
