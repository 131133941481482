<template>
	<b-navbar type="light" variant="light" fixed="top" sticky>
    <b-navbar-brand>
			<router-link
			to='/'
			>
			ValyStock
			</router-link>
		</b-navbar-brand>

    <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

    <b-collapse id="nav-collapse" is-nav>
      <b-navbar-nav class="ml-auto mt-2" v-if="this.$store.getters.getCurrentCamera">
        <h4 class="text-info">Cámara activa: {{this.$store.getters.getCurrentCamera.Numero}}</h4>
      </b-navbar-nav>
      <!-- Right aligned nav items -->
      <b-navbar-nav class="ml-auto">

        <b-nav-item-dropdown right>
          <!-- Using 'button-content' slot -->
          <template v-slot:button-content>
            <strong>{{ displayName }}</strong>
          </template>
          <b-dropdown-item @click="viewProfile">Perfil</b-dropdown-item>
          <b-dropdown-item @click="logout">Salir</b-dropdown-item>
        </b-nav-item-dropdown>
      </b-navbar-nav>
    </b-collapse>
  </b-navbar>	
</template>

<script>
import firebase from 'firebase/app';
import 'firebase/auth'

export default {
  methods: {
    logout() {
      firebase
        .auth()
        .signOut()
        .then(() => {
          this.$router.replace({
            name: "Login"
          });
        });
      this.$store.dispatch('resetStore')
    },
    viewProfile() {
      this.$router.push({
        name: 'UserProfile'
      })
    }
  },
	computed: {
    displayName: function () {
      if (this.$store.getters.user.profileData.displayName && this.$store.getters.user.profileData.displayName.length != 0)
        return this.$store.getters.user.profileData.displayName
      else
        return this.$store.getters.user.profileData.email
    }
	}	
}
</script>